/* eslint-disable eqeqeq */
/* eslint-disable default-case */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-concat */
/* eslint-disable no-eval */
import $ from "jquery";
import tableLayout from "./table-layout";
import { store } from "..";
import auth, {
  getImageById,
  isTop,
  nextDirection,
  levelOut,
  levelIn,
  selectPrevious,
  selectNext,
  clickSelected,
  previousTab,
  nextTab,
  isVisible,
  select,
  ajaxCallStart,
  ajaxCallEnd,
  confirmDialog,
  avoidMultipleClick,
  updateToolTips,
  replaceContent,
  get,
  getLocale2,
  getShortName,
  getLocale,
  isDuplicateClickEvent,
  parseNumber,
  post,
  messageDialog,
  getSetting
} from "./auth";
import { selectNewOrders, hideEditTable, submitNewOrders, getMenuItemAdditions, getAmountToPay } from "./tables-overview";
import { orderMenuItem, orderAnother, additionIsSelected, additionQuantitySelected } from "./orderMenuItem";
import { showMenuItems, getAvailableQuantityLabel } from "./chooseMenuItem";
import admin, {
  getTableMate,
  getMenuItem,
  deletedMenuItems,
  getDiscount,
  getProductionLine,
  getLocalPrinterSettings,
  printHtml,
  getPrinterForProductionLine,
  getPrinterForPrinterAction,
  storeLocalPrinterSettings,
  getPrinter,
  isAdmin,
  getPriceForQuantity,
  hasRole,
  getMenuCategory,
  getPickupTableNumber,
  getHomeDeliveryTableNumber,
  unlockTable,
  selectTableOccupationSelected
} from "./admin";
import { _preProcessOrders, allstates, alltimings, menuItemComparator } from "./order-grouping";
import { I18n } from "react-redux-i18n";
import SortedSet from "js-sorted-set";
import X2JS from "x2js";
import menuitemutil, { availableQuantities } from "./admin/menuitem";
import CustomerDeliveryDetails from "../components/CustomerDeliveryDetails";
import React from "react";
import ReactDOM from "react-dom";
import EatWithMeActions from "../actions/EatWithMeActions";
import moment from "moment";
import { getPaymentMethod } from "./tableMatePaid";
import clicksound from "../audio/button5.wav";


const _isFiltered = () => {
  //return orderListUtil.statesShownOriginal !== orderListUtil.statesShown;
  return orderListUtil.states !== orderListUtil.statesShown;
  //return typeof localStorage._editTable != "undefined";
};

export const states = ["cancelled", "selected", "ordered", "accepted", "producing", "delivering", "delivered", "paid"];

export const tmplparams = () => {
  var t = {
    getTableMate: getTableMate,
    getShortName: getShortName,
    statesShown: orderListUtil.statesShown,
    myStatus: orderListUtil.props.myStatus,
    orderListUtil: orderListUtil,
    getMenuItem: getMenuItem,
    auth: auth,
    war: auth.war,
    server: auth.server,
    getLocale: getLocale,
    I18n: I18n,
    getLocale2: getLocale2,
    local: orderListUtil.props.local,
    admin_local: orderListUtil.props.admin_local,
    getCategoryProperty: getCategoryProperty,
    getQuantity: getQuantity,
    getPriceForQuantity: getPriceForQuantity,
    getAvailableQuantityLabel: getAvailableQuantityLabel,
    getDuration: getDuration,
    getImageById: getImageById,
    hideEditTable: hideEditTable,
    mateInState: mateInState,
    getMenuItemAdditions: getMenuItemAdditions,
    additionIsSelected: additionIsSelected,
    menuitemutil: menuitemutil,
    additionQuantitySelected: additionQuantitySelected,
    hasRole: hasRole,
    keyDownHandler: _keyDownHandler,
    deleteOrders: deleteOrders,
    getOrderById: getOrderById,
    productionLineInvolved: productionLineInvolved,
    getProductionLine: getProductionLine,
    availableQuantities: availableQuantities,
    getQuantityLabel3: getQuantityLabel3,
    parseNumber: parseNumber,
  };
  t.tmplparams = t;
  return t;
};

$("body").on('keydown', function (e) {
  if (orderListUtil.keyDownHandler) {
    if (Array.isArray(orderListUtil.keyDownHandler))
      orderListUtil.keyDownHandler.forEach(handler => handler(e));
    else
      orderListUtil.keyDownHandler(e);
  }
  //else _keyDownHandler(e);
});

export const _keyDownHandler = e => {
  /*
   * if (e.which==16 || e.which==17 || e.which==18 || e.which==20 ||
   * (e.which>=112 && e.which<123)) return false;
   */
  if (e.target.localName === "TEXTAREA")
    return true;
  if (localStorage.locked == "true") return;
  if ($(".messageDialog").length > 0) return false;
  else if (orderListUtil.tablesOverview && tableLayout.contextmenu == true) {
    e.preventDefault();
    return true;
  }
  if (e.key == "r" && e.ctrlKey) {
    localStorage.removeItem("tableOccupations");
    localStorage.removeItem("orderListUtil.ordersCache");
    e.preventDefault();
    e.stopPropagation();
    // eslint-disable-next-line no-restricted-globals
    location.reload();
    return;
  }
  if (e.which == 122) return false;
  if (isTop($("#orderMenuItem.modal"))) {
    if ($("#orderMenuItem.modal #additions").length > 0 && $("#orderMenuItem.modal #additions").tokenize2() && typeof $("#orderMenuItem.modal #additions").tokenize2().dropdownShown != "undefined") {
      var $selected = $(".selectable.selectedTop").find(".selectable.selected");
      // $($selected.find('ul')).focus();
      // e.stopPropagation();
      if (e.which == 27)
        $("#orderMenuItem.modal #additions")
          .tokenize2()
          .trigger("tokenize:dropdown:hide");
      return true;
    } else
      switch (e.which) {
        case 27: // esc
          if (isTop($("#orderMenuItem.modal input#additionSearch")) && $("#orderMenuItem.modal input#additionSearch").val() != "") {
            $("#orderMenuItem.modal input#additionSearch").val("");
            $("#orderMenuItem.modal div.additionsBlock .list-group-item.optional").removeClass("hidden");
            return true;
          }
          $("#orderMenuItem.modal").modal("hide");
          e.preventDefault();
          break;
        case 13: // Enter
          break;
        case 37: // left
          if (!e.shiftKey && !e.ctrlKey && !e.altKey) {
            if (nextDirection() == "vertical") {
              levelOut();
              selectPrevious();
              levelIn();
            } else {
              selectPrevious();
            }
            $(":focus").blur();
            e.preventDefault();
          }
          break;
        case 39: // right
          if (!e.shiftKey && !e.ctrlKey && !e.altKey) {
            if (nextDirection() == "vertical") {
              levelOut();
              selectNext();
              levelIn();
            } else {
              selectNext();
            }
            $(":focus").blur();
            e.preventDefault();
          }
          break;
        case 38: // up
          if (!e.shiftKey && !e.ctrlKey && !e.altKey) {
            if (nextDirection() == "vertical") {
              selectPrevious();
            } else {
              levelOut();
              selectPrevious();
              levelIn(true);
            }
            $(":focus").blur();
            e.preventDefault();
          }
          break;
        case 40: // down
          if (!e.shiftKey && !e.ctrlKey && !e.altKey) {
            if (nextDirection() == "vertical") {
              selectNext();
            } else {
              levelOut();
              selectNext();
              levelIn();
            }
            $(":focus").blur();
            e.preventDefault();
          }
          break;
      }
    var z = $("input:focus");
    if (!e.isDefaultPrevented() && z.length != 1 && $("#search:focus").length == 0 && e.target.localName != "input") {
      $("#orderMenuItem.modal #additionSearch").focus();
    }

    if (e.isDefaultPrevented()) return true;

    var $selected = $(".selectable.selectedTop").find(".selectable.selected");
    // $(':focus').blur();
    if ($selected.attr("id") == "commentDiv" || $selected.attr("id") == "additionsDiv") {
      if (!$($selected.find("ul")).is(":focus")) $($selected.find("ul")).focus();
    }

    return e.target.localName != "input";
  } else if (isTop($("#askNumberOfSeats"))) {
    switch (e.which) {
      case 13: // left
        $('.modal.show button[type="submit"]').click();
        e.preventDefault();
        break;
    }
    return true;
  } else if ($("div#rejectionReason").length > 0 && $("div#rejectionReason").hasClass("show")) {
    switch (e.which) {
      case 32: // left
        clickSelected();
        e.preventDefault();
        break;
      case 37: // left
        if (!e.shiftKey && !e.ctrlKey && !e.altKey) {
          if (nextDirection() == "vertical") {
            levelOut();
            selectPrevious();
            levelIn();
          } else {
            selectPrevious();
          }
          e.preventDefault();
        }
        break;
      case 39: // right
        if (!e.shiftKey && !e.ctrlKey && !e.altKey) {
          if (nextDirection() == "vertical") {
            levelOut();
            selectNext();
            levelIn();
          } else {
            selectNext();
          }
          e.preventDefault();
        }
        break;
      case 38: // up
        if (!e.shiftKey && !e.ctrlKey && !e.altKey) {
          if (nextDirection() == "vertical") {
            selectPrevious();
          } else {
            levelOut();
            selectPrevious();
            levelIn(true);
          }
          e.preventDefault();
        }
        break;
      case 40: // down
        if (!e.shiftKey && !e.ctrlKey && !e.altKey) {
          if (nextDirection() == "vertical") {
            selectNext();
          } else {
            levelOut();
            selectNext();
            levelIn();
          }
          e.preventDefault();
        }
        break;

      case 13: // down
        $("div#rejectionReason")
          .find("#save")
          .click();
        e.preventDefault();
        break;
      case 27: // esc
        $("div#rejectionReason").modal("hide");
        e.preventDefault();
        break;
      default:
        $($(this).find("input#reasonText")).focus();
    }
    return true;
  } else if (isTop($("#byCustomer.tab-pane.selectedTop")) || isTop($("#byTiming.tab-pane.selectedTop"))) {
    switch (e.which) {
      case 37: // left
        if (e.ctrlKey) {
          previousTab();
        } else levelOut();
        break;
      case 39: // right
        if (e.ctrlKey) {
          nextTab();
        } else levelIn();
        break;

      case 38: // up
        if (!e.shiftKey && !e.ctrlKey && !e.altKey) {
          selectPrevious();
        }
        break;

      case 40: // down
        if (!e.shiftKey && !e.ctrlKey && !e.altKey) {
          selectNext();
        }
        break;
      case 27: // esc
        window.hideEditTable();
        break;
      case 107: // +
        selectNewOrders();
        break;
      case 113: // F2
        $('.nav-link[href="#orders"]').click();
        break;
      case 114: // F3
        $('.nav-link[href="#byCustomer"]').click();
        break;
      case 115: // F4
        $('.nav-link[href="#byTiming"]').click();
        break;
      case 116: // F5
        $("button#unlock").click();
        break;
      default:
        if (!e.isDefaultPrevented() && isVisible($("#editTable #right_pane"))) {
          var hasFocus = $("#editTable #right_pane input#search").is(":focus");
          if (!$("#editTable #right_pane input#search").is(":focus")) {
            $("#editTable #right_pane input#search").focus();
            select($("#editTable #right_pane input#search"));
          }
          return true;
        }
    }
    e.preventDefault();
    return true;
  } else if (isTop($("#drink_menu_tab.tab-pane.selectedTop")) || isTop($("#meal_menu_tab.tab-pane.selectedTop")) || isTop($("#right_pane .tab-content.selectedTop"))) {
    switch (e.which) {
      /*
       * case 37: // left if (!e.shiftKey && !e.altKey) { if (e.ctrlKey) {
       * previousTab(); } else if (!e.ctrlKey) levelOut(); e.preventDefault(); }
       * break; case 39: // right if (!e.shiftKey && !e.altKey) { if
       * (e.ctrlKey) { nextTab(); } else if (!e.ctrlKey) levelIn();
       * e.preventDefault(); } break;
       */
      case 38: // up
        if (!e.shiftKey && !e.ctrlKey && !e.altKey) {
          selectPrevious();
          e.preventDefault();
        }
        break;
      case 40: // down
        if (!e.shiftKey && !e.ctrlKey && !e.altKey) {
          selectNext();
          e.preventDefault();
        }
        break;
      case 13: // down
        if (!e.shiftKey && !e.ctrlKey && !e.altKey) {
          e.preventDefault();
          var v = $(".selectable.selectedTop .selectable.selected");
          if ((v.hasClass("menuItem") && !v.hasClass("variationHead")) || v.hasClass("menuItemVariation")) orderMenuItem(v, v.attr("entityType"), v.attr("menuItem"), v.attr("quantity"));
          else eval(v.attr("onclick"));
          e.preventDefault();
        }
        break;
      case 113: // F2
        orderListUtil.doNotSelect = true;
        $('.nav-link[href="#orders"]').click();
        e.preventDefault();
        break;
      case 114: // F3
        orderListUtil.doNotSelect = true;
        $('.nav-link[href="#byCustomer"]').click();
        e.preventDefault();
        break;
      case 115: // F4
        orderListUtil.doNotSelect = true;
        $('.nav-link[href="#byTiming"]').click();
        e.preventDefault();
        break;
      case 116: // F5
        e.preventDefault();
        break;
      case 27: // esc
        try {
          var s = $("#search");
          if (s.val() != "") {
            s.val("");
            showMenuItems("");
          } else {
            var v = $(".tab-pane.selectedTop").find(".selectable.selected2");
            if (v.length > 0) {
              eval($(v.get(v.length - 1)).attr("onclick"));
              select(v.get(v.length - 1));
              // v.addClass('selected');
            } else {
              submitNewOrders(true, false);
            }
          }
        } catch (ex) {
          console.log(ex);
        }
        e.preventDefault();
        break;
      default:
        if (!e.isDefaultPrevented() && isVisible($("#editTable #right_pane"))) {
          var hasFocus = $("#editTable #right_pane input#search").is(":focus");
          if (!$("#editTable #right_pane input#search").is(":focus")) {
            $("#editTable #right_pane input#search").focus();
            select($("#editTable #right_pane input#search"));
          }
        }
    }
    return true;
  } else if (isTop($("#orders.tab-pane.selectedTop"))) {
    switch (e.which) {
      case 37: // left
        if (e.ctrlKey) {
          previousTab();
        } else orderListUtil.prevState(e);
        break;
      case 39: // right
        if (e.ctrlKey) {
          nextTab();
        } else orderListUtil.nextState(e);
        break;

      case 38: // up
        selectPreviousOrder(e);
        break;

      case 40: // down
        selectNextOrder(e);
        break;

      case 13: // down
        toggleDetails();
        e.preventDefault(); // prevent the default action (scroll / move
        // caret)
        break;
      case 107: // +
        selectNewOrders();
        break;
      /*
       * case 112: // F1 $('button#lock').click(); break;
       */
      case 113: // F2
        $('.nav-link[href="#orders"]').click();
        break;
      case 114: // F3
        $('.nav-link[href="#byCustomer"]').click();
        break;
      case 115: // F4
        $('.nav-link[href="#byTiming"]').click();
        break;
      case 116: // F5
        $("button#unlock").click();
        break;
      case 27: // down
        window.hideEditTable();
        e.preventDefault();
        break;
      default:
        if (!e.isDefaultPrevented() && isVisible($("#editTable #right_pane"))) {
          var hasFocus = $("#editTable #right_pane input#search").is(":focus");
          if (!$("#editTable #right_pane input#search").is(":focus")) {
            $("#editTable #right_pane input#search").focus();
            select($("#editTable #right_pane input#search"));
          }
        } else e.preventDefault();
    }
    return true;
  } else if (isTop($("#editTable"))) {
    switch (e.which) {
      case 107: // +
        if (isVisible($("button#new-orders"))) selectNewOrders();
        else $("button#lock").click();
        e.preventDefault();
        break;
      case 113: // F2
        $('.nav-link[href="#orders"]').click();
        break;
      case 114: // F3
        $('.nav-link[href="#byCustomer"]').click();
        break;
      case 115: // F4
        $('.nav-link[href="#byTiming"]').click();
        break;
      case 116: // F5
        $("button#unlock").click();
        break;
      case 27: // esc
        window.hideEditTable();
        e.preventDefault();
        break;
      case 37: // left
      case 39: // right
      case 38: // up
      case 40: // down
        break;
      default:
        if (e.target.localName != "input" && !e.isDefaultPrevented() && isVisible($("#editTable #right_pane"))) {
          var hasFocus = $("#editTable #right_pane input#search").is(":focus");
          if (!$("#editTable #right_pane input#search").is(":focus")) {
            $("#editTable #right_pane input#search").focus();
            select($("#editTable #right_pane input#search"));
          }
        }
    }
  } else {
    switch (e.which) {
      case 27: // esc
        break;
      /*
    case 13: // left
      $(".selectable.selectedTop .selectable.selected").click();
      e.preventDefault();
      break;*/
      case 37: // left
        if (!e.shiftKey && !e.ctrlKey && !e.altKey) {
          if (nextDirection() == "vertical") {
            levelOut();
            selectPrevious();
            levelIn();
          } else {
            selectPrevious();
          }
          if (e.target.localName !== "input") {
            $(":focus").blur();
            e.preventDefault();
          }
        }
        break;
      case 39: // right
        if (!e.shiftKey && !e.ctrlKey && !e.altKey) {
          if (nextDirection() == "vertical") {
            levelOut();
            selectNext();
            levelIn();
          } else {
            selectNext();
          }
          if (e.target.localName !== "input") {
            $(":focus").blur();
            e.preventDefault();
          }
        }
        break;
      case 38: // up
        if (!e.shiftKey && !e.ctrlKey && !e.altKey) {
          if (nextDirection() == "vertical") {
            selectPrevious();
          } else {
            levelOut();
            selectPrevious();
            levelIn(true);
          }
          if (e.target.localName !== "input") {
            $(":focus").blur();
            e.preventDefault();
          }
        }
        break;
      case 40: // down
        if (!e.shiftKey && !e.ctrlKey && !e.altKey) {
          if (nextDirection() == "vertical") {
            selectNext();
          } else {
            levelOut();
            selectNext();
            levelIn();
          }
          if (e.target.localName !== "input") {
            $(":focus").blur();
            e.preventDefault();
          }
        }
        break;
    }
  }
};

export const deleteOrders = (ids, skip) => {
  if (!Array.isArray(ids)) ids = ids.split(",");
  var change = false;
  ids.forEach(function (id) {
    orderListUtil.ordersCache.forEach(function (t, ind) {
      if (t.id == id) {
        change |= (t.recordState === "ACTIVE");
        orderListUtil.ordersCache.splice(ind, 1);
      }
    });
    orderListUtil.newOrders.forEach(function (t, ind) {
      if (t.id == id) {
        change |= (t.recordState === "ACTIVE");
        orderListUtil.newOrders.splice(ind, 1);
      }
    });
  });
  if (change && typeof skip == "undefined" && ids.length) updateOrders([]);
};

export const getOrderById = id => {
  var order = orderListUtil.ordersCache.find(t => t.id == id);

  return order;
};

export const updatePayingMates = () => {
  orderListUtil.ordersPaidByMap = {};
  orderListUtil.ordersCache.forEach(function (order) {
    if (order.state != "selected") {
      try {
        order = $.extend(true, {}, order);
        var forWhom = getTableMate(order.forWhomId);
        if (forWhom == null) {
          // Most probably the order is for a table which was closed, but
          // the table occupation was not closed;
          return;
        }
        var ordersPaidBy = orderListUtil.ordersPaidByMap["pm_" + forWhom.payingMateId];
        if (typeof ordersPaidBy == "undefined") {
          orderListUtil.ordersPaidByMap["pm_" + forWhom.payingMateId] = [order];
        } else {
          ordersPaidBy.push(order);
        }
        order.quantityLabel = getQuantity(order);
        order.addition = true;
        var d = order.discountComment;
        order.discountComment = "";
        if (typeof getDiscount != "undefined" && d != null && d != "")
          d.split(";").forEach(function (dis) {
            var dis2 = dis.split(",");
            order.discountComment += I18n.t("admin_local.discount") + ": " + getDiscount(dis2[0]).name + " " + "\r\n";
          });
      } catch (ex) {
        console.log(ex);
        console.log(ex.stack);
        //alert("Error " + ex + " occured when processing order " + order.id);
      }
    }
  });
}

export const updateOrders = updatedOrders => {
  var def = $.Deferred();

  orderListUtil.processAllOrdersDone = false;
  ajaxCallStart("processing13");

  if (orderListUtil.ordersCache)
    orderListUtil.ordersCache = orderListUtil.ordersCache.filter(t => getTableMate(t.forWhomId) != null);
  else
    orderListUtil.ordersCache = [];

  var deletedMenuItemIds = [];

  var newAcceptedOrder = false;

  updatedOrders.forEach(function (v) {
    if (v.recordState == "DELETED" && v.id > 0) {
      orderListUtil.ordersCache = orderListUtil.ordersCache.filter(o => o.id != v.id);
      return;
    }
    var childs = [];
    v.childOrders.forEach(function (child) {
      if (child.recordState != "DELETED") childs.push(child);
    });
    v.childOrders = childs;
    var found = false;
    if (v.id > 0) {
      if (found) return;
      orderListUtil.ordersCache.forEach(function (t, ind) {
        if (v.id === t.id) {
          orderListUtil.ordersCache[ind] = v;
          found = true;
        }
      });
    }
    if (found) {
      return;
    }
    orderListUtil.ordersCache.forEach(function (t, ind) {
      if (found) return;
      //if (t.id < 0 && v.id > 0) {
      //this order is a new locally created order not yet confirmed by the server
      //we should check if one of such order was confirmed now
      //the match depends on menuItemId, forWhomId, orderedById, quantity
      //if (t.tempId == v.tempId) {

      if (v.id > 0 && v.id == t.id) {
        found = true;
        orderListUtil.ordersCache[ind] = v;
        return;
      }

      if (t.id < 0) {
        if (v.id < 0 && t.id == v.id) {
          orderListUtil.ordersCache[ind] = v;
          found = true;
        }
      }

      orderListUtil.newOrders.forEach((o, ind2) => {
        if (found) return;
        if (o.tempId == t.tempId) {
          if (v.id > 0 && t.tempId.startsWith(v.tempId)) {
            found = true;
            orderListUtil.newOrders.splice(ind2, 1);
            if (v.id > 0) {
              if (orderListUtil.ordersCache[ind].state != "accepted" && v.state == "accepted" && v.orderedById != auth.myStatus.id) newAcceptedOrder = true;
              o.tempId = v.tempId;
              orderListUtil.ordersCache[ind] = v;
            }
          }
        }
      })
      //}
      //} else 
      //if (!found && (t.id == v.id || t.tempId == v.tempId)) {
      if (!found && (t.id == v.id)) {
        found = true;
        if (orderListUtil.ordersCache[ind].state != "accepted" && v.state == "accepted" && v.orderedById != auth.myStatus.id) newAcceptedOrder = true;
        orderListUtil.ordersCache[ind] = v;
      }
    });

    deleteOrders(updatedOrders.filter(order => order.recordState == "DELETED").map(o => o.id), true);

    if (!found) {
      orderListUtil.ordersCache.push(v);
      var m = getMenuItem(v.menuItem.id);
      if (m == null) {
        if (deletedMenuItemIds.indexOf(v.menuItem.id) == -1) deletedMenuItemIds.push(v.menuItem.id);
      }
      v.childOrders.forEach(function (c) {
        var m = getMenuItem(c.menuItem.id);
        if (m.type == "deleted") {
          if (deletedMenuItemIds.indexOf(v.menuItem.id) == -1) deletedMenuItemIds.push(v.menuItem.id);
        }
      });
      if (v.state == "accepted" && v.orderedById != auth.myStatus.id) {
        if (typeof orderListUtil.menuItemtype != "undefined" && orderListUtil.menuItemtype != null) {
          if (v.menuItem.type != orderListUtil.menuItemtype) return;
        } else if (!orderListUtil.filter(v)) return;
        newAcceptedOrder = true;
      }
    }

  });

  orderListUtil.ordersCache.sort((a, b) => Math.abs(a.id) - Math.abs(b.id))

  //processAllOrders();

  updatePayingMates();

  if (newAcceptedOrder && typeof playNewAcceptedOrderSound != "undefined") {
    playNewAcceptedOrderSound();
  }
  /*
  localStorage.ordersCache = compressToBase64(JSON.stringify(orderListUtil.ordersCache));
  */

  admin.getMenuItemsByIds(deletedMenuItemIds, function (data) {
    data.forEach(function (item) {
      deletedMenuItems.push(item);
    });
    if (orderListUtil.tableOccupations) {
      const statesShown2 = orderListUtil.statesShown;
      //orderListUtil.statesShown = orderListUtil.states;
      processAllOrders(orderListUtil.ordersCache);
      //orderListUtil.statesShown = statesShown2;
      if (orderListUtil.isFiltered()) preProcessOrders(orderListUtil.ordersCache);
    } else {
      if (orderListUtil.isFiltered()) preProcessOrders(orderListUtil.ordersCache);
      else processAllOrders(orderListUtil.ordersCache);
    }

    var o = orderListUtil.isFiltered() ? orderListUtil.orders : orderListUtil.allOrders;

    o.tables.forEach(function (v, ind) {
      v.orders.forEach(function (v1, ind2) {
        v1.index = v.orderSequenceNumber + "-" + ind2;
      });
    });

    orderListUtil.detailsMode = false;
    orderListUtil.showAllMode = false;
    var orderList = $("tbody#orderList");
    var statesShownBackup = orderListUtil.statesShown;
    if (typeof orderListUtil.statesDisplayed != "undefined") {
      orderListUtil.statesShown = orderListUtil.statesDisplayed;
    }
    var unprinted = 0;
    if (typeof orderListUtil.orderTemplate != "undefined") {
      orderListUtil.orderTemplate(o.tables);
    } else {
      o.tables.forEach(function (order) {
        if (orderListUtil.show(order)) {
          unprinted += order.unprinted;
        }
      });
      updateOrderList(true);
    }
    if (typeof orderListUtil.statesDisplayed != "undefined") {
      orderListUtil.statesShown = statesShownBackup;
    }

    var id = localStorage.drinkbar_id;
    var details = "true" == localStorage.drinkbar_details;
    var detailWasSelected = localStorage.drinkbar_detailWasSelected;
    var orderId = localStorage.drinkbar_orderId;

    setSelected(id, detailWasSelected, orderId);
    if (details) {
      showDetails(true);
      if (detailWasSelected == "detailrow") {
        var r = $("tr#" + id + ".detailrow.nothidden");
        r.each(function (ind, row) {
          if ($(row).attr("orderId") == orderId) {
            // = "";
            _show($(row));
          }
        });
      }
    }
    if (unprinted > 0 && localStorage.selectNewOrders != "true") {
      $("div#print-new-orders").show();
    } else {
      $("div#print-new-orders").hide();
    }
    orderListUtil.updateOrdersDone && orderListUtil.updateOrdersDone();
    orderListUtil.updateHooks && orderListUtil.updateHooks();
    ajaxCallEnd("processing13");
    def.resolve();
  });
  return def.promise();
};

export const preProcessOrders = orderlist => {
  orderListUtil.orders = _preProcessOrders(orderlist, orderListUtil.statesShown, orderListUtil.filter);
};

export const getOrdersPaidBy = (id, date) => {
  var orders = orderListUtil.ordersPaidByMap["pm_" + id];
  if (typeof orders == "undefined") return [];
  if (date)
    return orders.filter(o => moment(o.todeliver).startOf("day").valueOf() === moment(date).startOf("day").valueOf());
  return orders;
};

export const processAllOrders = (orderlist = orderListUtil.ordersCache) => {
  if (orderListUtil.processAllOrdersDone) return;
  orderListUtil.processAllOrdersDone = true;
  const statesShown2 = orderListUtil.statesShown;
  orderListUtil.statesShown = orderListUtil.states;
  orderListUtil.allOrders = _preProcessOrders(orderlist, orderListUtil.states, orderListUtil.filter);
  orderListUtil.statesShown = statesShown2;
  store.dispatch(EatWithMeActions.orders(orderListUtil.allOrders));
};

export const processOrders = (orders2, showEditTable = true, first = true) => {
  ajaxCallStart("processing12");
  orderListUtil.currentOrderSequenceNumber = 0;

  const now = new Date().getTime();

  try {
    orderListUtil.selected = undefined;
    var v = $("th#unprocessed");
    v.removeClass("highlight");
    v.text("");

    var v = $("th#waiting");
    v.removeClass("highlight");
    v.text("");

    var id = localStorage.drinkbar_id;
    var details = "true" == localStorage.drinkbar_details;
    var detailWasSelected = localStorage.drinkbar_detailWasSelected;
    var orderId = localStorage.drinkbar_orderId;


    if (orderListUtil.isFiltered()) {
      preProcessOrders(orders2);
    } else {
      if (typeof allOrders == "undefined") {
        processAllOrders(orders2);

        orderListUtil.allOrders.tables.forEach(function (v, ind) {
          v.orders.forEach(function (v1, ind2) {
            v1.index = v.orderSequenceNumber + "-" + ind2;
          });
        });
      }
    }

    store.dispatch(EatWithMeActions.orders(orderListUtil.allOrders));

    orderListUtil.detailsMode = false;
    orderListUtil.showAllMode = false;

    var o = orderListUtil.isFiltered() ? orderListUtil.orders : orderListUtil.allOrders;
    var unprinted = 0;
    var thereAreOrders = false;
    if (typeof orderListUtil.orderTemplate != "undefined") {
      orderListUtil.orderTemplate(o.tables);
    } else {
      //$("#orderList").empty();
      updateOrderList(true, false);
      var price = 0;
      o.tables.forEach(function (order) {
        if (orderListUtil.show(order)) {
          price += order.price;
          orderListUtil.currentOrderSequenceNumber = Math.max(orderListUtil.currentOrderSequenceNumber, order.orderSequenceNumber);
          unprinted += order.unprinted;
          thereAreOrders = true;
        }
      });
    }

    if (thereAreOrders) $("#editTable .tableNumber").hide();
    else if (showEditTable) $("#editTable .tableNumber").show();

    if (typeof id == "undefined" || $("tr#" + id).length == 0) {
      setSelected($("tr.mainrow.unprocessed").attr("id"), "mainrow");
    }

    if (typeof orderListUtil.selected != "undefined") {
      var selectedId = orderListUtil.selected.attr("id");
      var isOrderrow = orderListUtil.selected.hasClass("orderrow");
      var orderId = orderListUtil.selected.attr("orderId");
    } else if (typeof id != "undefined") {
      setSelected(id, detailWasSelected, orderId);
      if (typeof orderListUtil.selected != "undefined" && details) {
        showDetails(true);
        if (detailWasSelected == "detailrow") {
          var r = $("tr#" + id + ".detailrow.nothidden");
          r.each(function (ind, row) {
            if ($(row).attr("orderId") == orderId) {
              //selectedId = "";
              _show($(row));
            }
          });
        }
      }
    }

    orderListUtil.processOrdersDone(orderListUtil.orders.tables, showEditTable, first);
  } finally {
    ajaxCallEnd("processing12");
  }

  if (unprinted > 0 && localStorage.selectNewOrders != "true") {
    $("div#print-new-orders").show();
  } else {
    $("div#print-new-orders").hide();
  }

  // orderListUtil.updateHooks();
};

export const deleteTableOccupations = (ids) => {
  ids.split(",").forEach(function (id) {

    orderListUtil.ordersCache = orderListUtil.ordersCache.filter(order => Number(order.tableOccupationId) !== Number(id));
    orderListUtil.tableOccupations = orderListUtil.tableOccupations.filter(tableOccupation => Number(tableOccupation.id) !== Number(id))
    if (typeof localStorage.tableOccupationSelected != undefined && localStorage.tableOccupationSelected == id) {
      delete localStorage.tableOccupationSelected;
    }

  });
  ajaxCallStart("processing12");
  updateTableMates().done(function () {
    try {
      update({ tableOccupations: [], orders: [] });
    } finally {
      ajaxCallEnd("processing12");
    }
  });
}

function mousedownHandler(element) { }

function mouseupHandler(event) {
  const target = $(event.target).parents("tr.orderrow,tr.mainrow,tr.detailrow");
  if (target.length !== 1)
    return;
  if (isDuplicateClickEvent(event)) return;
  avoidMultipleClick(event.target, event);
  if (event.button && event.button != 0) return;
  if (orderListUtil.dragged) return;
  if (isTop($("#editMenuItemDialog"))) return;
  _show($(target));
  _show2($(target));
}

export const getTableOccupation = (id = localStorage.tableOccupationSelected) => {
  var tableOccupation;
  orderListUtil.tableOccupations && orderListUtil.tableOccupations.forEach(function (o) {
    if (o.id == id) tableOccupation = o;
  });
  return tableOccupation;
};

export const getTableOccupationTablesForTable = number => {
  var tableOccupationTables = [];
  orderListUtil.tableOccupations.forEach(function (o) {
    o.restaurantTables.forEach(function (table) {
      if (table.restaurantTable.number == number) tableOccupationTables.push(table);
    });
  });
  return tableOccupationTables;
};

export const getTable = number => {
  var res = null;
  if (auth.myStatus.restaurant_tables)
    auth.myStatus.restaurant_tables.forEach(function (table) {
      if (table.number == number) res = table;
    });
  return res;
};

export const getTableName = number => {
  var res = null;
  if (auth.myStatus.restaurant_tables)
    res = auth.myStatus.restaurant_tables.find(table => table.number == number);
  if (!res)
    return number;
  return res.name ? res.name : String.format(I18n.t("admin_local.table_number"), res.number);
};

export const getTableName2 = number => {
  var res = null;
  if (auth.myStatus.restaurant_tables)
    res = auth.myStatus.restaurant_tables.find(table => table.number == number);
  if (!res)
    return number;
  return res.name ? res.name : res.number;
};

export const isTakeawayTable = number => {
  return Number(number) === getPickupTableNumber() || Number(number) === getHomeDeliveryTableNumber()
}

export const updateTableOccupations = (data, orders) => {
  if (typeof orders == "undefined") orders = [];
  var def = new $.Deferred();
  // reset free seats
  if (orderListUtil.tablesOverview)
    tableLayout.tables.forEach(function (table) {
      table.freeSeats = table.seats;
    });
  $(data).each(function (ind, tableOccupation) {
    var restaurantTables = [];
    tableOccupation.restaurantTables.forEach(function (table, ind) {
      if (table.active) {
        restaurantTables.push(table);
      }
    });
    tableOccupation.restaurantTables = restaurantTables;
    var found = false;
    $(orderListUtil.tableOccupations).each(function (ind2, tableOccupation2) {
      if (tableOccupation.id == tableOccupation2.id) {
        if (tableOccupation.active) {
          orderListUtil.tableOccupations[ind2] = tableOccupation;
        } else {
          orderListUtil.tableOccupations.splice(ind2, 1);
          if (typeof localStorage.tableOccupationSelected !== "undefined" && localStorage.tableOccupationSelected == tableOccupation.id) {
            delete localStorage.tableOccupationSelected;
          }
        }
        found = true;
      }
    });
    if (!found && tableOccupation.active) {
      orderListUtil.tableOccupations.push(tableOccupation);
    }
  });
  if (orderListUtil.tablesOverview)
    orderListUtil.tableOccupations.forEach(function (tableOccupation) {
      tableOccupation.restaurantTables.forEach(function (table) {
        if (table.restaurantTable === null) return;
        var t = getTable(table.restaurantTable.number);
        if (t)
          t.freeSeats = Math.max(0, t.freeSeats - table.tableMateCount);
      });
    });
  ajaxCallStart("processing10");
  updateTableMates().done(function () {
    try {
      updateOrders(orders);
      if (orderListUtil.tablesOverview) tableLayout.updateTableOccupations();
      if (orderListUtil && orderListUtil?.tableOccupations?.length > 0) {
        $("a.nav-link[href='#byCustomer']").trigger("click");
      } else {
        $("a.nav-link[href='#orders']").trigger("click");
      }



      def.resolve();
    } finally {
      ajaxCallEnd("processing10");
    }
  });
  return def.promise();
};

export const updateTableMates = () => {
  const now = new Date().getTime();
  var def = new $.Deferred();
  admin.tableMates = [];
  admin.tableMatesMap.clear();

  if (typeof orderListUtil.tableOccupations != "undefined") {
    orderListUtil.occupationsSelected = [];
    var selectedFound = null;
    var first = null;
    var thereAreMore = false;
    orderListUtil.tableOccupations.forEach(function (occupation) {
      var activeTableIds = [];
      occupation.restaurantTables.forEach(function (restaurantTableOccupation) {
        if (restaurantTableOccupation.active) {
          activeTableIds.push(restaurantTableOccupation.id);
        }
      });
      occupation.tableMates.filter(t => t.active).forEach(function (tableMate) {
        if (activeTableIds.indexOf(tableMate.tableId) >= 0) {
          admin.tableMates.push(tableMate.id);
        }
        admin.tableMatesMap.set(Number(tableMate.id), tableMate);
      });
      occupation.restaurantTables.forEach(function (restaurantTableOccupation) {
        if (!restaurantTableOccupation.active || restaurantTableOccupation.restaurantTable === null) return;

        var number = restaurantTableOccupation.restaurantTable.number;
        if (Number(number) == Number(localStorage.tableNumberSelected)) {
          orderListUtil.occupationsSelected.push(occupation);
          if (occupation.id == localStorage.tableOccupationSelected && selectedFound == null) {
            selectedFound = localStorage.tableOccupationSelected;
          }
          if (first) thereAreMore = true;
          first = occupation.id;
        }
      });
    });
    if (selectedFound == null) {
      if (!first) {
      } else {
        try {
          if (typeof localStorage.tableOccupationSelected !== "undefined" && getTableOccupation(localStorage.tableOccupationSelected)?.restaurantTables.find(t => t.restaurantTable.number == localStorage.tableNumberSelected)) {

          } else {
            const oldOccupation = getTableOccupation(localStorage.tableOccupationSelected);
            if (localStorage._editTable !== "true" || (!thereAreMore && !oldOccupation)) {
              selectTableOccupationSelected(first, true);
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    } else {
    }
  }

  def.resolve();

  if (admin.tableMates) {
    var mates = [];
    admin.tableMates.forEach(tableMateId => {
      var tableMate = getTableMate(tableMateId);
      if (tableMate.active == true && tableMate.tableOccupationId == localStorage.tableOccupationSelected && tableMate.tableNumber == localStorage.tableNumberSelected) {
        mates.push(tableMate);
      }
    });
    const stickyMates = $('#userSelector button.active.sticky');
    const stickyIds = [];
    stickyMates.each((ind, m) => stickyIds.push(m.id));
    if (mates.length > 1) $("#userSelector").replaceWith($("#userSelectorTemplate").tmpl({ tableMates: mates, getTableMate: getTableMate, local_name: I18n.t("local.name") }));
    else $("#userSelector").empty();
    stickyIds.forEach(id => {
      const b = $("#userSelector button#" + id);
      b.addClass("active");
      b.addClass("sticky");
      b.find('input').prop('checked', true);
    })
  }

  store.dispatch(EatWithMeActions.tableOccupations(orderListUtil.tableOccupations));

  return def.promise();
};

export const printNewOrders = (ordersSelected, untilTiming, reprint, force) => {
  try {
    if (ordersSelected && typeof ordersSelected === "string")
      ordersSelected = ordersSelected.split(",").map(n => Number(n));
  } catch (ex) {
    console.error(ex);
  }
  if (ordersSelected && typeof ordersSelected[0] == "number") {
    ordersSelected = ordersSelected.map(o => getOrderById(o));
  }
  if (force != true && typeof untilTiming != "undefined" && untilTiming != null) {
    const untilTimingLabel = getLocale2(auth.myStatus.restaurant_settings.timings[untilTiming !== 'inherit' ? untilTiming : "asap"].langs).name;
    confirmDialog(
      I18n.t("local.confirmation"),
      String.format(
        reprint == true ? I18n.t("admin_local.do_you_really_want_to_reprint_the_orders_until_timing") : I18n.t("admin_local.do_you_really_want_to_print_the_orders_until_timing"),
        untilTimingLabel
      )
    ).done(function () {
      _printNewOrders(ordersSelected, untilTiming, reprint);
    });
  } else if (force != true && typeof ordersSelected != "undefined" && ordersSelected != null) {
    confirmDialog(
      I18n.t("local.confirmation"),
      reprint == true ? I18n.t("admin_local.do_you_really_want_to_reprint_only_the_selected_orders") : I18n.t("admin_local.do_you_really_want_to_print_only_the_selected_orders")
    ).done(function () {
      _printNewOrders(ordersSelected, untilTiming, reprint);
    });
  } else {
    _printNewOrders(ordersSelected, untilTiming, reprint);
  }
};

function _printNewOrders(ordersSelected, untilTiming, reprint) {
  if (typeof reprint == "undefined") reprint = false;
  var orderIds = [];
  var productionLines = new SortedSet({
    comparator: function (a, b) {
      return Math.abs(a.id) - Math.abs(b.id);
    }
  });
  /*
  const isPickup = Number(localStorage.tableNumberSelected) === auth.myStatus.restaurant_settings["enabled-features"].pickup["table-number"];
  const isHomeDelivery = Number(localStorage.tableNumberSelected) === auth.myStatus.restaurant_settings["enabled-features"].homedelivery["table-number"];
  */

  var ordersByTiming = [];
  var o = /*orderListUtil.orders ? orderListUtil.orders :*/ orderListUtil.allOrders;
  o.tables.forEach(function (order) {
    if (ordersSelected || orderListUtil.show(order)) {
      if (ordersSelected && order.tableOccupation != localStorage.tableOccupationSelected) {
        return;
      }
      var pls = [];
      order.timingOrder = alltimings.indexOf(order.timing);
      for (var pl in order.productionLinesInvolved) {
        if (pl == "pl") continue;
        if (!productionLines.contains(order.productionLinesInvolved[pl])) productionLines.insert(order.productionLinesInvolved[pl]);
        if (pls.indexOf(pl.id) < 0) {
          pls.push(Number(order.productionLinesInvolved[pl].id));
        }
      }
      order.productionLinesInvolvedIds = JSON.stringify(pls);
      order.productionLinesInvolvedIds = order.productionLinesInvolvedIds.substring(1, order.productionLinesInvolvedIds.length - 1);
      var orderCopy = JSON.parse(JSON.stringify(order));
      orderCopy.quantityLabel = orderCopy.quantityLabel ? orderCopy.quantityLabel.replace("&nbsp;", " ") : orderCopy.quantityLabel;
      //var orderCopy = Object.assign({}, order);
      ordersByTiming.push(orderCopy);

      orderCopy.orders.forEach(function (order) {
        order.orders.forEach(function (order) {
          order.orderIdsStr = JSON.stringify(order.orderIds);
          order.menuItem = getMenuItem(order.menuItem.id);
          order.path = menuitemutil.getPath(order.menuItem);
          order.childOrders.forEach(function (corder) {
            corder.menuItem = getMenuItem(corder.menuItem.id);
            corder.quantityLabel = getQuantity(corder, order.quantity);
          });
          order.orders.forEach(function (order) {
            delete order.statePerProductionLine;
            order.quantityLabel = getQuantity(order);
            order.menuItem = getMenuItem(order.menuItem.id);

            if (order.menuItem.defaultFromDate) {
              if (!ordersSelected && order.menuItem.defaultFromDate !== moment().startOf("day").valueOf()) {
                return;
              }
            }

            order.childOrders.forEach(function (corder) {
              corder.menuItem = getMenuItem(corder.menuItem.id);
              corder.quantityLabel = getQuantity(corder, order.quantity);
            });
            var notprinted = typeof order.printed == "undefined" || order.printed == false || reprint == true;
            if (notprinted && (order.state == "accepted" || order.state == "producing" || (typeof ordersSelected != "undefined" && ordersSelected != null))) {
              if (typeof ordersSelected == "undefined" || ordersSelected == null || ordersSelected.find(o => o.id === order.id || o.tempId === order.tempId)) {
                delete order.tempId;
                console.log(order.id);
                if (typeof untilTiming == "undefined" || untilTiming == null || alltimings.indexOf(untilTiming) >= alltimings.indexOf(order.timing)) {
                  //console.log(order);
                  var productionLinesInvolvedIds = [];
                  order.defaultProductionLinesIds.split(",").forEach(function (d) {
                    if (d == "") return;
                    var pl = getProductionLine(d);
                    var printed = 1;
                    //var printFailed = 2;
                    var partPrinted = 3;
                    var beingPrinted = 4;
                    var printStatus = order.printStatus["pl" + pl.id];
                    if ((reprint == true || (printStatus != printed && printStatus != partPrinted && printStatus != beingPrinted)) && productionLinesInvolvedIds.indexOf(pl.id) == -1)
                      productionLinesInvolvedIds.push(pl.id);
                    pl.collaboratingProductionLineIds.forEach(function (cpl) {
                      var printStatus = order.printStatus["pl" + cpl];
                      if ((reprint == true || (printStatus != printed && printStatus != partPrinted && printStatus != beingPrinted)) && productionLinesInvolvedIds.indexOf(cpl) == -1) productionLinesInvolvedIds.push(cpl);
                    });
                  });
                  productionLinesInvolvedIds = JSON.stringify(productionLinesInvolvedIds);
                  orderIds.push({ id: order.id, defaultProductionLines: productionLinesInvolvedIds.substring(1, productionLinesInvolvedIds.length - 1) });
                }
              }
            }
          });
        });
      });
    }
  });
  var pls = [];
  var rev = ordersByTiming.reverse();
  $(rev).each(function (ind, order) {
    for (var pl in order.productionLinesInvolved) {
      if (pl == "pl") continue;
      if (pls.indexOf(pl.id) < 0) {
        pls.push(Number(order.productionLinesInvolved[pl].id));
      }
    }
    order.productionLinesInvolvedIds = JSON.stringify(pls);
    order.productionLinesInvolvedIds = order.productionLinesInvolvedIds.substring(1, order.productionLinesInvolvedIds.length - 1);
  });
  if (orderIds.length == 0) {
    return;
  }
  var pls = [];
  productionLines.forEach(function (pl) {
    if (!(pls.find(p => p.id === pl.id)))
      pls.push(pl);
  });

  var timings = [];
  Object.keys(auth.myStatus.restaurant_settings.timings).forEach(key => {
    auth.myStatus.restaurant_settings.timings[key].langs.forEach(l => {
      if (l.lang == auth.myStatus.restaurant_settings["default-language"]) timings.push({ id: key, name: l.name });
    });
  });

  const tableOccupation = getTableOccupation(localStorage.tableOccupationSelected);

  if (!tableOccupation)
    return;
  const table = getTable(localStorage.tableNumberSelected);

  const bookedProperties = tableOccupation.takeaway && tableOccupation.bookedProperties ? { ...tableOccupation.bookedProperties } : {};

  if (bookedProperties.surname === "null")
    delete tableOccupation.bookedProperties
  if (bookedProperties.postcode === "null")
    delete tableOccupation.postcode;

  if (bookedProperties.door)
    bookedProperties.address += " " + bookedProperties.door;

  bookedProperties.date = tableOccupation.bookedOccupationStart;
  bookedProperties.start = tableOccupation.bookedOccupationStart;

  var x2js = new X2JS({ arrayAccessForm: "property" });
  const ordersByTiming2 = JSON.parse(JSON.stringify(ordersByTiming));
  function clear(a) {
    delete a.inStateDurations;
    delete a.inStateTimeStamp;
    delete a.stateMap;
    delete a.discount;
    delete a.orderIds;
    delete a.unprinted;
    delete a.partprinted;
    delete a.printFailed;
    delete a.printed;
    delete a.beingPrinted;
    delete a.price;
    delete a.discountedPrice;
    delete a.tableMateDiscount;
    delete a.oldestOrder;
    delete a.discountComment;
    delete a.rows2;
    delete a.unprocessed;
    delete a.wasSelectedForPayment;
    delete a.fullPrice;
    delete a.currency;
    delete a.statePerProductionLine;
    delete a.modificationTimeStamp;
    delete a.created;
    delete a.forWhomId;
    delete a.printStatus;
    delete a.vat;
    delete a.paid;
    delete a.customPrice;
    delete a.previousState;
    delete a.ordersInState;
    delete a.orderedByCustomer;
    delete a.restaurantComment;

    if (a.menuItem) {
      const m = a.menuItem;
      a.menuItem = { id: m.id, name: m.name, shortName: m.shortName, quantityType: m.quantityType, defaultProductionLineIds: m.defaultProductionLineIds, manufacturer: m.manufacturer, globalOrder: m.globalOrder };
    }
  }
  function a2(a) {
    clear(a);
    if (a.orders) {
      a.orders.forEach(o => {
        a2(o);
      })
    }
    if (a.childOrders) {
      a.childOrders.forEach(o => {
        a2(o);
      })
    }
  }


  ordersByTiming2.forEach(o1 => {
    a2(o1);
  })

  orderIds = orderIds.filter(o => getOrderById(o.id));

  orderIds.sort((o1, o2) => {
    const oo1 = getOrderById(o1.id);
    const oo2 = getOrderById(o2.id);
    return menuItemComparator(oo1, oo2);
  })

  //console.log(ordersByTiming2);
  //rdersByTiming2.forEach(d => d.orders.forEach(d => console.log(menuitemutil.getPath(getMenuItem(d.menuItem.id)))))

  var xmlAsStr = '<?xml version="1.0" encoding="utf-8"?>' + x2js.js2xml({ top: { table: table, timings: timings, ordersByTiming: ordersByTiming2, orders: { order: orderIds }, bookedProperties: bookedProperties, bigOrderNumber: auth.myStatus.restaurant_settings["tables-overview"].bigOrderNumber } });
  var file = "orders_" + localStorage.id + new Date().getTime() + ".xml";

  //console.log(xmlAsStr);

  printKitchenReceipts(
    "../birt/preview?__report=orders.rptdesign&__format=html&__locale=" + auth.myStatus.restaurant_settings["default-language"] + "&ds=" + file + "&target=html&reprint=" + reprint,
    xmlAsStr,
    orderIds,
    pls,
    reprint
  );

}

function printKitchenReceipts(url, xml, orderIds, pls, reprint) {
  var printerOptions = [];
  Object.keys(getLocalPrinterSettings().productionLines).forEach(function (key) {
    printerOptions.push({ productionLine: key.substring(3), printer: getLocalPrinterSettings().productionLines[key] });
  });
  admin.printKitchenReceipt(printerOptions, xml, orderIds, pls, reprint, function (data) {
    update(data);
    data.toBePrintedLocally.forEach(function (data) {
      data.orders.forEach(orderId => {
        var order = orderListUtil.getOrderById(orderId);
        order.printStatus["pl" + data.productionLine] = "1";
        order.printed = true;
      });
      printHtml(data.html, admin.getPrinterForProductionLine(getProductionLine(data.productionLine)).id);
    });
    updateOrders([]);
    if (orderListUtil.tablesOverview) tableLayout.updateTableOccupations();
  });
}

export const localPrinters = [
];

export const refreshPrinters = () => {
  localPrinters.length = 0;
  if (auth.device.platform !== "browser" && window.cordova.epos2) {
    console.log("Discover Epson printers");
    window.cordova.epos2.startDiscover(deviceInfo => {
      localPrinters.push({
        id: "epson/" + deviceInfo.target,
        type: "epson",
        name: deviceInfo.deviceName + "(" + deviceInfo.target + ")",
      });
      updatePrintersStatus();
    }, error => {
      console.error("EPOS discovery failure", JSON.stringify(error))
    });
  }
  if (auth.device.platform !== "browser" && window.cordova.plugins.btprinter && window.cordova.plugins.btprinter.forEach) {
    console.log("Discover BT printers");
    window.cordova.plugins.btprinter.forEach(deviceInfo => {
      deviceInfo.forEach(deviceInfo => {
        localPrinters.push({
          id: "bt/" + deviceInfo.address,
          type: "bt",
          name: deviceInfo.name + "(" + deviceInfo.address + ")",
        });
      });
      updatePrintersStatus();
    }, error => {
      console.error("BT printer discovery failure", JSON.stringify(error))
    });
  } else if (auth.device.platform !== "browser" && window.cordova.plugins.btprinter && window.cordova.plugins.btprinter.find) {
    console.log("Discover BT printers");
    window.cordova.plugins.btprinter.find(deviceInfo => {
      deviceInfo.forEach(deviceInfo => {
        localPrinters.push({
          id: "bt/" + deviceInfo.address,
          type: "bt",
          name: deviceInfo.name + "(" + deviceInfo.address + ")",
        });
      });
      updatePrintersStatus();
    }, error => {
      console.error("BT printer discovery failure", JSON.stringify(error))
    });
  }
  admin.getPrintersStatus(updatePrintersStatus);
}

export const updatePrintersStatus = data => {
  if (typeof data != "undefined") {
    auth.myStatus.restaurant_printers = data.restaurant_printers;
    auth.myStatus.restaurant_printer_actions = data.restaurant_printer_actions;
    auth.myStatus.restaurant_production_lines = data.restaurant_production_lines;
    if (data.restaurant_cash_registers) auth.myStatus.restaurant_cash_registers = data.restaurant_cash_registers;
  }
  var menu = $("#printers.dropdown-menu");
  var error = false;
  $("#printers.dropdown-menu div").remove();
  $("#printers.dropdown-menu button").remove();
  if (data?.printerServerUrl) {
    menu.append('<div style="display:flex;flex-flow:column;align-items:end" class="dropdown-item"><div onclick="get(\'http://localhost:8080/printerService/restart\').fail(() => auth.ajaxError=false)"  class="icon-resize-small btn btn-primary">' + I18n.t("admin_local.restart_printerserver") + '</div><div onclick="get(\'http://localhost:8080/printerService/reboot\').fail(() => auth.ajaxError=false)" class="icon-loop btn btn-primary ">' + I18n.t("admin_local.reboot_printerserver") + '</div></div><div class="dropdown-divider"></div>');
  } else {
    if (hasRole(auth.myStatus.roles, ["superadmin"])) {
      menu.append('<div style="display:flex;flex-flow:column;align-items:end" class="dropdown-item"><div onclick="sendMessage(\'\', \'restart-printer-server\',\'Printer\',\'\')"  class="icon-resize-small btn btn-primary " style="margin-bottom:10px">' + I18n.t("admin_local.restart_printerserver") + '</div><div onclick="sendMessage(\'\', \'reboot-printer-server\',\'Printer\',\'\')" class="icon-loop btn btn-primary ">' + I18n.t("admin_local.reboot_printerserver") + '</div></div><div class="dropdown-divider"></div>');
    }
  }
  menu.append('<div class="">' + I18n.t("admin_local.production_lines") + "</div>");
  auth.myStatus.restaurant_production_lines.forEach(function (pl) {
    var printer = getPrinterForProductionLine(pl);
    if (printer != null && printer.name == "local") {
      menu.append('<div style="display:flex" onclick=""  class="dropdown-item icon-connected">' + pl.name + " -> " + printerChooserForProductionLine(pl) + "</div>");
    } else if (printer != null && printer.connectedPrinter != "" && (printer.status == "" || printer.status == null)) {
      menu.append('<div style="display:flex" onclick=""  class="dropdown-item icon-connected">' + pl.name + " -> " + printerChooserForProductionLine(pl) + " -> " + printer.connectedPrinter + "</div>");
    } else if (printer != null && printer.connectedPrinter != "") {
      error = true;
      var status = printer.status;
      if (status.startsWith("String.format")) status = eval(status);
      if (I18n.t("admin_local." + status)) status = I18n.t("admin_local." + status);
      menu.append('<div style="display:flex" onclick=""  class="dropdown-item icon-error">' + pl.name + " -> " + printerChooserForProductionLine(pl) + " -> " + printer.connectedPrinter + " -> " + status + "</div>");
    } else if (printer != null) {
      error = true;
      menu.append('<div style="display:flex" onclick=""  class="dropdown-item icon-disconnected">' + pl.name + " -> " + printerChooserForProductionLine(pl) + " -> ???</div>");
    } else {
      menu.append('<div style="display:flex" onclick=""  class="dropdown-item icon-disconnected">' + pl.name + " -> " + printerChooserForProductionLine(pl) + "</div>");
    }
  });
  menu.append('<div class="dropdown-divider"></div>');
  menu.append('<div class="">' + I18n.t("admin_local.printer_actions") + "</div>");
  Object.keys(auth.myStatus.restaurant_printer_actions).forEach(function (key) {
    var printer = getPrinterForPrinterAction(key);
    if (printer.name == "local") {
      menu.append('<div style="display:flex" onclick=""  class="dropdown-item icon-connected">' + I18n.t("admin_local." + key) + " -> " + printerChooserForPrinterAction(key, printer) + "</div>");
    } else if (printer != null && printer.connectedPrinter != "" && (printer.status == "" || printer.status == null)) {
      menu.append(
        '<div style="display:flex" onclick=""  class="dropdown-item icon-connected">' + I18n.t("admin_local." + key) + " -> " + printerChooserForPrinterAction(key, printer) + " -> " + printer.connectedPrinter + "</div>"
      );
    } else if (printer != null && printer.connectedPrinter != "") {
      error = true;
      var status = printer.status;
      if (status.startsWith("String.format")) status = eval(status);
      if (I18n.t("admin_local." + status)) status = I18n.t("admin_local." + status);
      menu.append(
        '<div style="display:flex" onclick=""  class="dropdown-item icon-error">' +
        I18n.t("admin_local." + key) +
        " -> " +
        printerChooserForPrinterAction(key, printer) +
        " -> " +
        printer.connectedPrinter +
        " -> " +
        status +
        "</div>"
      );
    } else {
      error = true;
      menu.append('<div style="display:flex" onclick=""  class="dropdown-item icon-disconnected">' + I18n.t("admin_local." + key) + " -> " + printerChooserForPrinterAction(key, printer) + " -> ???</div>");
    }
  });
  menu.append('<div class="dropdown-divider"></div>');

  menu.append('<div class="">' + I18n.t("admin_local.menus.cashRegisters") + "</div>");
  auth.myStatus.restaurant_cash_registers && auth.myStatus.restaurant_cash_registers.forEach(cashRegister => {
    if (cashRegister != null && cashRegister.connectedDevice != "" && (cashRegister.status == "" || cashRegister.status == null)) {
      var dayFunctions = "";
      switch (cashRegister.type) {
        case "fiscat":
        case "fiscat2":
        case "fiscat3":
        case "fiscat3a":
        case "fiscat4":
        case "fiscat5":
        case "a234":
          dayFunctions = "<div style='flex-grow:1'/><button class='btn btn-primary ' style='margin-left:1rem;width:unset;padding:.1rem'  onclick='dayOpen(\"" + cashRegister.name + "\")'> " + I18n.t("admin_local.cashier_dayopen") + " </button>" + "<button class='btn  btn-danger ' style='margin-left:1rem;width:unset;padding:.1rem' onclick='dayClose(\"" + cashRegister.name + "\")'> " + I18n.t("admin_local.cashier_dayclose") + " </button>";
          break;
      }
      menu.append('<div style="display:flex;align-items:center" onclick=""  class="dropdown-item icon-connected"><span>' + cashRegister.name + " -> " + cashRegister.connectedDevice + "</span>" + dayFunctions + "</div>");
    } else if (cashRegister != null && cashRegister.connectedDevice != "") {
      var status = cashRegister.status;
      if (status.startsWith("String.format")) status = eval(status);
      if (I18n.t("admin_local." + status)) status = I18n.t("admin_local." + status);
      menu.append('<div style="display:flex" onclick=""  class="dropdown-item icon-error">' + cashRegister.name + " -> " + cashRegister.connectedDevice + " -> " + status + "</div>");
    } else if (cashRegister != null) {
      menu.append('<div style="display:flex" onclick=""  class="dropdown-item icon-disconnected">' + cashRegister.name + " -> ???</div>");
    }
  });

  menu.append('<div class="dropdown-divider"></div>');

  menu.append('<div class="">' + I18n.t("admin_local.menus.bankTerminals") + "</div>");
  auth.myStatus.restaurant_bank_terminals && auth.myStatus.restaurant_bank_terminals.forEach(bankTerminal => {
    if (bankTerminal != null && bankTerminal.connectedDevice != "" && (bankTerminal.status == "" || bankTerminal.status == null)) {
      menu.append('<div style="display:flex" onclick=""  class="dropdown-item icon-connected">' + bankTerminal.name + " -> " + bankTerminal.connectedDevice + "</div>");
    } else if (bankTerminal != null && bankTerminal.connectedDevice != "") {
      var status = bankTerminal.status;
      if (status.startsWith("String.format")) status = eval(status);
      if (I18n.t("admin_local." + status)) status = I18n.t("admin_local." + status);
      menu.append('<div style="display:flex" onclick=""  class="dropdown-item icon-error">' + bankTerminal.name + " -> " + bankTerminal.connectedDevice + " -> " + status + "</div>");
    } else if (bankTerminal != null) {
      menu.append('<div style="display:flex" onclick=""  class="dropdown-item icon-disconnected">' + bankTerminal.name + " -> ???</div>");
    }
  });

  menu.append('<div class="dropdown-divider"></div>');

  menu.append('<div class="">' + I18n.t("admin_local.printers") + "</div>");
  auth.myStatus.restaurant_printers.forEach(function (printer) {
    var pl = { printer: printer };
    if (pl.printer != null && pl.printer.name == "local") {
      menu.append('<div style="display:flex" onclick=""  class="dropdown-item icon-connected">' + pl.printer.name + "</div>");
    } else if (pl.printer != null && pl.printer.connectedPrinter != "" && (pl.printer.status == "" || pl.printer.status == null)) {
      menu.append('<div style="display:flex" onclick=""  class="dropdown-item icon-connected">' + pl.printer.name + " -> " + pl.printer.connectedPrinter + "</div>");
    } else if (pl.printer != null && pl.printer.connectedPrinter != "") {
      var status = pl.printer.status;
      if (status.startsWith("String.format")) status = eval(status);
      if (I18n.t("admin_local." + status)) status = I18n.t("admin_local." + status);
      menu.append('<div style="display:flex" onclick=""  class="dropdown-item icon-error">' + pl.printer.name + " -> " + pl.printer.connectedPrinter + " -> " + status + "</div>");
    } else if (pl.printer != null) {
      menu.append('<div style="display:flex" onclick=""  class="dropdown-item icon-disconnected">' + pl.printer.name + " -> ???</div>");
    }
  });
  localPrinters.forEach(pl => {
    menu.append('<div style="display:flex" onclick=""  class="dropdown-item icon-connected">local-' + pl.name + "</div>");
  })

  if (error) {
    $("button#printers").removeClass("ok");
    $("button#printers").addClass("highlight-6");
    $("button#printers").css("color", "red");
  } else {
    $("button#printers").addClass("ok");
    $("button#printers").removeClass("highlight-6");
    $("button#printers").css("color", "black");
  }
};

export const printerChooserForProductionLine = pl => {
  if (pl.printer == null) return I18n.t("admin_local.non_printer_configured");
  var dropDownButton = '<div class="dropdown" style="">';
  var style = getLocalPrinterSettings().productionLines["pl_" + pl.id] ? "color:red;font-weight:bold" : "";

  dropDownButton +=
    "<button onclick=\"event.preventDefault();event.stopPropagation();$('#printerPicker" +
    pl.id +
    '.dropdown-menu\').toggle()" class="btn dropdown-toggle" style="' +
    style +
    ';font-size:1rem;width:7rem;height:1rem;margin:0;padding:0;margin-right:2rem;margin-top:0.2rem;line-height: 1rem;" type="button" id="printerPicker' +
    pl.id +
    '" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">' +
    (getPrinterForProductionLine(pl) ? getPrinterForProductionLine(pl).name : "N/A") +
    "</button>";
  dropDownButton += '<div class="dropdown-menu" id="printerPicker' + pl.id + '" style="" x-placement="bottom-start">';

  if (pl.printer.name == "local") {
    dropDownButton += '<button onclick="unsetPrinterForProductionLine(' + pl.id + ')" class="dropdown-item icon-connected">' + I18n.t("admin_local.default") + " - " + pl.printer.name + "</button>";
  } else if (pl.printer != null && pl.printer.connectedPrinter != "" && (pl.printer.status == "" || pl.printer.status == null))
    dropDownButton += '<button onclick="unsetPrinterForProductionLine(' + pl.id + "," + pl.printer.id + ')" class="dropdown-item icon-connected">' + I18n.t("admin_local.default") + " - " + pl.printer.name + "</button>";
  else {
    dropDownButton +=
      '<button onclick="unsetPrinterForProductionLine(' + pl.id + ",'" + pl.printer.id + "'" + ')" class="dropdown-item icon-disconnected">' + I18n.t("admin_local.default") + " - " + pl.printer.name + "</button>";
  }
  auth.myStatus.restaurant_printers.forEach(function (printer) {
    if (printer.name == "local") {
      dropDownButton += '<button onclick="setPrinterForProductionLine(' + pl.id + "," + printer.id + ');" class="dropdown-item icon-connected">local</button>';
    } else if (printer != null && printer.connectedPrinter != "" && (printer.status == "" || printer.status == null))
      dropDownButton += '<button onclick="setPrinterForProductionLine(' + pl.id + "," + printer.id + ');" class="dropdown-item icon-connected">' + printer.name + "</button>";
    else {
      dropDownButton += '<button onclick="setPrinterForProductionLine(' + pl.id + "," + printer.id + ');" class="dropdown-item icon-disconnected">' + printer.name + "</button>";
    }
  });
  localPrinters.forEach(printer => {
    dropDownButton += '<button onclick="setPrinterForProductionLine(' + pl.id + ",'" + printer.id + "'" + ');" class="dropdown-item icon-connected">local-' + printer.name + "</button>";
  })
  dropDownButton += "</div>";
  dropDownButton += "</div>";
  return dropDownButton;
};

export const printerChooserForPrinterAction = (pa, printer) => {
  var dropDownButton = '<div class="dropdown" style="">';
  var style = getLocalPrinterSettings().printerActions["pa_" + pa] ? "color:red;font-weight:bold" : "";

  dropDownButton +=
    "<button onclick=\"event.preventDefault();event.stopPropagation();$('#printerPicker" +
    pa +
    '.dropdown-menu\').toggle()" class="btn dropdown-toggle" style="' +
    style +
    ';font-size:1rem;width:7rem;height:1rem;margin:0;padding:0;margin-right:2rem;margin-top:0.2rem;line-height: 1rem;" type="button" id="printerPicker' +
    pa +
    '" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">' +
    (printer.name ? printer.name : printer) +
    "</button>";
  dropDownButton += '<div class="dropdown-menu" id="printerPicker' + pa + '" style="" x-placement="bottom-start">';

  if (printer.name == "local") {
    dropDownButton += "<button onclick=\"unsetPrinterForPrinterAction('" + pa + '\')" class="dropdown-item icon-connected">' + I18n.t("admin_local.default") + " - " + printer.name + "</button>";
  } else if (pa.printer != null && pa.printer.connectedPrinter != "" && (pa.printer.status == "" || pa.printer.status == null))
    dropDownButton += "<button onclick=\"unsetPrinterForPrinterAction('" + pa + "'," + printer.id + ')" class="dropdown-item icon-connected">' + I18n.t("admin_local.default") + " - " + printer.name + "</button>";
  else {
    dropDownButton += "<button onclick=\"unsetPrinterForPrinterAction('" + pa + "'," + printer.id + ')" class="dropdown-item icon-disconnected">' + I18n.t("admin_local.default") + " - " + printer.name + "</button>";
  }
  auth.myStatus.restaurant_printers.forEach(function (printer) {
    if (printer.name == "local") {
      dropDownButton += "<button onclick=\"setPrinterForPrinterAction('" + pa + "'," + printer.id + ');" class="dropdown-item icon-connected">local</button>';
    } else if (printer != null && printer.connectedPrinter != "" && (printer.status == "" || printer.status == null))
      dropDownButton += "<button onclick=\"setPrinterForPrinterAction('" + pa + "'," + printer.id + ');" class="dropdown-item icon-connected">' + printer.name + "</button>";
    else {
      dropDownButton += "<button onclick=\"setPrinterForPrinterAction('" + pa + "'," + printer.id + ');" class="dropdown-item icon-disconnected">' + printer.name + "</button>";
    }
  });
  localPrinters.forEach(printer => {
    dropDownButton += "<button onclick=\"setPrinterForPrinterAction('" + pa + "','" + printer.id + "'" + ');" class= "dropdown-item icon-connected" > local - ' + printer.name + "</button>";
  })

  dropDownButton += "</div>";
  dropDownButton += "</div>";
  return dropDownButton;
};

export const setPrinterForProductionLine = (productionLine, printer) => {
  getLocalPrinterSettings().productionLines["pl_" + productionLine] = printer;
  storeLocalPrinterSettings();
  $("#printerPicker" + productionLine + ".dropdown-menu").toggle();
  $("#printerPicker" + productionLine + ".dropdown-toggle").text(getPrinter(printer) ? getPrinter(printer).name : printer);
  $("#printerPicker" + productionLine + ".dropdown-toggle").css("color", "red");
  $("#printerPicker" + productionLine + ".dropdown-toggle").css("font-weight", "bold");
  updatePrintersStatus();
};

export const unsetPrinterForProductionLine = (productionLine, printer) => {
  delete getLocalPrinterSettings().productionLines["pl_" + productionLine];
  storeLocalPrinterSettings();
  $("#printerPicker" + productionLine + ".dropdown-menu").toggle();
  $("#printerPicker" + productionLine + ".dropdown-toggle").text(getPrinter(printer) ? getPrinter(printer).name : printer);
  $("#printerPicker" + productionLine + ".dropdown-toggle").css("color", "");
  $("#printerPicker" + productionLine + ".dropdown-toggle").css("font-weight", "");
  updatePrintersStatus();
};

export const setPrinterForPrinterAction = (printerAction, printer) => {
  getLocalPrinterSettings().printerActions["pa_" + printerAction] = printer;
  storeLocalPrinterSettings();
  $("#printerPicker" + printerAction + ".dropdown-menu").toggle();
  $("#printerPicker" + printerAction + ".dropdown-toggle").text(getPrinter(printer) ? getPrinter(printer).name : printer);
  $("#printerPicker" + printerAction + ".dropdown-toggle").css("color", "red");
  $("#printerPicker" + printerAction + ".dropdown-toggle").css("font-weight", "bold");
  updatePrintersStatus();
};

export const unsetPrinterForPrinterAction = (printerAction, printer) => {
  delete getLocalPrinterSettings().printerActions["pa_" + printerAction];
  storeLocalPrinterSettings();
  $("#printerPicker" + printerAction + ".dropdown-menu").toggle();
  $("#printerPicker" + printerAction + ".dropdown-toggle").text(getPrinter(printer) ? getPrinter(printer).name : printer);
  $("#printerPicker" + printerAction + ".dropdown-toggle").css("color", "");
  $("#printerPicker" + printerAction + ".dropdown-toggle").css("font-weight", "");
  updatePrintersStatus();
};

export const updateHooks = ($editTable) => {

  const now = new Date().getTime();

  var $orderrows = $(".freeze-orderTable > table.orderTable");
  $orderrows.unbind("click", mouseupHandler);
  $orderrows.click(mouseupHandler);

  $editTable = $('.freeze-orderTable');

  if (!(auth.device.platform !== "browser" && auth.speed > 40)) {

    $editTable.find("> table.orderTable .order.draggable").draggable({
      delay: 300,
      axis: "x",
      snap: ".state td",
      snapMode: "center",
      scroll: false,
      revert: function (event, target) {
        return orderListUtil.revert;
      },
      zindex: 1000,
      stack: ".state td",
      start: function (e, ui) {
        orderListUtil.dragged = isTop($(e.currentTarget));
        return orderListUtil.dragged;
      },
      drag: function (e, ui) {
        orderListUtil.revert = true;
        $(".comment").hide();
        $(".restaurantComment").hide();
      },
      stop: function (e, ui) {
        orderListUtil.dragged = false;
        $(".comment").show();
        $(".restaurantComment").show();
      }
    });

    $editTable.find("> table.orderTable .state.ui-droppable").droppable({
      accept: ".order.draggable",
      classes: {
        "ui-droppable-hover": "ui-state-hover2"
      },
      drop: function (event, ui) {
        avoidMultipleClick(this, event);
        var $this = $(this);
        var order = $(ui.draggable).attr("order");
        var state1 = $this.attr("state");
        var state2 = $(ui.draggable).attr("state");
        if (allstates.indexOf(state1) < allstates.indexOf(state2) && !hasRole(auth.myStatus.roles, ["admin", "superadmin"]) && !getSetting("tables-overview.always_allow_delete")) {
          return false;
        }
        if ($(ui.draggable).attr("state") != $this.attr("state")) {
          changeStateByTableAndMenuItem(ui.draggable, $(ui.draggable).attr("state"), $this.attr("state"), $(ui.draggable).attr("timing"));
        }
      }
    });
  }
  updateToolTips();
  /*
  $("#orderScrollable.scrollable-y").scroll(function () {
    var unprocessed = $(this).find("tr.nothidden.orderrow.unprocessed");
    var waiting = $(this).find("tr.nothidden.orderrow.unprocessed");
    if (typeof orderListUtil.selected == "undefined") return;
    var s = orderListUtil.selected.parents(".scrollable-y");
    if (s.length == 0) return;
    if (unprocessed.length > 0) {
      var count = 0;
      unprocessed.each(function (ind, e) {
        if ($(e).isInViewport(s) >= 0) return;
        var ordered = $(e)
          .find("div.orderrowtd#ordered")
          .attr("count");
        var accepted = $(e)
          .find("div.orderrowtd#accepted")
          .attr("count");
        ordered = ordered == undefined ? 0 : ordered;
        accepted = accepted == undefined ? 0 : accepted;
        count += Number(ordered) + Number(accepted);
      });
      var v = $("th#unprocessed");
      v.addClass("highlight");
      v.text(count);
    } else {
      var v = $("th#unprocessed");
      v.removeClass("highlight");
      v.text("");
    }
    if (waiting.length > 0) {
      var count = 0;
      waiting.each(function (ind, e) {
        if ($(e).isInViewport(s) <= 0) return;
        var ordered = $(e)
          .find("div.orderrowtd#ordered")
          .attr("count");
        var accepted = $(e)
          .find("div.orderrowtd#accepted")
          .attr("count");
        ordered = ordered == undefined ? 0 : ordered;
        accepted = accepted == undefined ? 0 : accepted;
        count += Number(ordered) + Number(accepted);
      });
      var v = $("th#waiting");
      v.addClass("highlight");
      v.text(count);
    } else {
      var v = $("th#waiting");
      v.removeClass("highlight");
      v.text("");
    }
  });
  */
  auth.updateHooks();
}

$.fn.isInViewport = function (v) {
  var elementTop = $(this).offset().top - v.offset().top;
  var elementBottom = elementTop + $(this).height();

  var viewportTop = 0;
  var viewportBottom = viewportTop + v.innerHeight();

  var r = elementBottom < viewportTop ? -1 : elementTop > viewportBottom ? 1 : 0;
  return r;
};

function getWaitingTime(time) {
  var date = new Date(time);
  var now = new Date();
  var allseconds = Math.round((now - date) / 1000);
  var hours = Math.floor(allseconds / 3600);
  var minutes = Math.floor(allseconds / 60);
  var seconds = Math.floor(allseconds % 60);
  var newdate = new Date();
  newdate.setHours(hours);
  newdate.setMinutes(minutes);
  newdate.setSeconds(seconds);
  return newdate.toLocaleTimeString();
}

export const setSelected = (id, clazz, orderId) => {
  const table = $(":not(.clone-head-table-wrap):not(.clone-column-table-wrap) > table.orderTable");
  if (typeof orderId != "undefined" && orderId != "undefined") {
    var selected2 = table.find("tr#" + id + ".orderrow");
    _show(selected2);
    showDetails(false);
    selected2 = table.find('tr[orderId="' + orderId + '"]');
  } else {
    if (clazz == "detailrow") clazz = "orderrow";
    var selected2 = $("tr#" + id + "." + clazz);
  }
  if (selected2.length == 0) {
    if (typeof id != "undefined" && id.split("-").length > 2) {
      clazz = id.split("-").length == 4 ? "orderrow" : "mainrow";
      if (setSelected(id.substring(0, id.lastIndexOf("-")), clazz, orderId)) showDetails(true);
      return;
    }
    orderListUtil.selected = undefined;
    if (table.find("tr.mainrow.unprocessed").length > 0) setSelected(table.find("tr.mainrow.unprocessed").attr("id"), "mainrow");
  } else {
    orderListUtil.selected = selected2;
    _show(orderListUtil.selected);
    showDetails();
    return true;
  }
};

function selectRow(element) {
  hideDetails();
  orderListUtil.selected = $(element);
  showDetails();
}

export const selectPreviousOrder = () => {
  if (typeof orderListUtil.selected == "undefined") return;
  var v1 = $(orderListUtil.selected.prevAll("tr.nothidden").get(0));
  if (v1.length > 0) {
    v1.get(0).scrollIntoView({ behavior: "auto", block: "nearest", inline: "nearest" });
    _show(v1);
  }
};

export const selectNextOrder = () => {
  if (typeof orderListUtil.selected == "undefined") return;
  var v1 = $(orderListUtil.selected.nextAll("tr.nothidden").get(0));
  if (v1.length > 0) {
    v1.get(0).scrollIntoView({ behavior: "auto", block: "nearest", inline: "nearest" });
    _show(v1);
  }
};

var newSelection = false;
export const _show = v1 => {
  $("#editTable :not(.clone-head-table-wrap) table.orderTable tr.selected").removeClass("selected");
  var selected1 = v1.attr("id");
  localStorage.drinkbar_orderId = v1.attr("orderId");
  if (typeof selected1 != "undefined") {
    if (typeof orderListUtil.selected == "undefined" || selected1 != $(orderListUtil.selected).attr("id")) {
      if (typeof orderListUtil.selected != "undefined") {
        var $selected = $(orderListUtil.selected);
        hideDetails();
        if (typeof orderListUtil.selected != "undefined") {
          $selected.removeClass("selected");
          $selected.removeClass("highlight");
        }
      }
      orderListUtil.selected = v1;
      localStorage.drinkbar_id = selected1;
      v1.addClass("selected");
      showDetails(false);
      newSelection = true;
    } else {
      if (typeof orderListUtil.selected != "undefined") {
        orderListUtil.selected.removeClass("selected");
      }
      v1.addClass("selected");
      orderListUtil.selected = v1;
      localStorage.drinkbar_id = selected1;
    }
  }
  $("#editTable tr.highlight").removeClass("highlight");
  var v = $("th#unprocessed");
  v.removeClass("highlight");
  v.text("");
  var v = $("th#waiting");
  v.removeClass("highlight");
  v.text();
  $("#editTable #" + selected1 + ".childOrder").addClass("selected");
  $("#editTable #" + selected1 + ".childOrder").addClass("highlight");

  if (typeof orderListUtil.selected != "undefined") {
    localStorage.drinkbar_id = orderListUtil.selected.attr("id");
    localStorage.drinkbar_details = $("tr#" + orderListUtil.selected.attr("id") + ".detailrow.nothidden").length > 0;
    localStorage.drinkbar_detailWasSelected = orderListUtil.selected.hasClass("mainrow") ? "mainrow" : orderListUtil.selected.hasClass("orderrow") ? "orderrow" : "detailrow";
    if (localStorage.drinkbar_detailWasSelected == "mainrow") {
      $("#editTable :not(.clone-head-table-wrap) table.orderTable")
        .find('tr[timing="' + localStorage.drinkbar_id + '"]')
        .addClass("highlight");
    } else if (localStorage.drinkbar_detailWasSelected == "orderrow") {
      $("#editTable :not(.clone-head-table-wrap) table.orderTable")
        .find('tr.detailrow[id="' + orderListUtil.selected.attr("id") + '"]')
        .addClass("highlight");
    } else {
    }

    localStorage.drinkbar_orderId = orderListUtil.selected.attr("orderId");
  }
};

function _show2(v1) {
  var selected1 = v1.attr("id");
  if (newSelection) {
    newSelection = false;
    return;
  }
  if (selected1 == orderListUtil.selected.attr("id") && v1.hasClass("orderrow")) {
    toggleDetails();
    return;
  } else if (selected1 == orderListUtil.selected.attr("id") && v1.hasClass("mainrow")) {
    toggleShowAll();
    return;
  }
}

export const toggleDetails = () => {
  if (orderListUtil.detailsMode) hideDetails();
  else showDetails(true);
};

function toggleShowAll() {
  if (orderListUtil.detailsMode) hideShowAll();
  else showAll(true);
}

export const showDetails = force => {
  if (typeof orderListUtil.selected == "undefined") return;
  var attr = $(orderListUtil.selected).attr("id");
  const table = $(".freeze-orderTable > table");

  $("#views").removeClass("calendar-view");
  $("#views .day").addClass("hidden");
  $("#views .week").remove();

  /*
  if ($(orderListUtil.selected).attr("orderid")) {
    const placeholder = $(orderListUtil.selected).next();
    if (placeholder.hasClass("placeholder")) {
      try {
        var html = placeholder.html().trim();
        console.log(html);
        //html = html.substring(10).trim();
        console.log(html);
        //html = html.substring(0, html.length - 3);
        console.log(html);
        const r = $(html);
        placeholder.replaceWith(r);
      } catch (ex) {
        console.error(placeholder.html());
        console.error(ex);
      }
    }
  }*/

  var detailRows = table.find("tr#" + attr + ".detailrow");
  if (force || (typeof attr !== typeof undefined && attr !== false && detailRows.find("div.comment").length + detailRows.find("div.restaurantComment").length > 0)) {
    if (detailRows.length == 0) return;

    detailRows.removeClass("hidden");
    detailRows.addClass("nothidden");
    orderListUtil.detailsMode = true;
    localStorage.drinkbar_details = true;
    table.find("tr.orderrow").removeClass("selected");
    orderListUtil.selected.addClass("selected");
    orderListUtil.selected.addClass("expanded");
    orderListUtil.selected.removeClass("collapsed");
    var timing = orderListUtil.selected.attr("timing");
    var m = table.find("tr#" + timing + ".mainrow").find("td")[0];
    var id = orderListUtil.selected.attr("id");
    var d = table.find("tr#" + id + ".detailrow");
    var r = Number($(m).attr("rowspanbk")) + d.length;
    $(m).attr("rowspan", r);

    var s = id.split("-");

    // var o = $('tr#' + timing + '-0.orderrow').find('td')[0];
    // $(o).attr('rowspan', Number($(o).attr('rowspanbk')) + d.length);

    var o = table.find("tr#" + id + ".orderrow.main, tr#" + id + ".childOrder").find("td[rowspanbk]");
    if (s[s.length - 1] != "0") {
      var id0 = id.substring(0, id.lastIndexOf("-")) + "-0";
      var o1 = table.find("tr#" + id0 + ".orderrow.first").find("td");
      $(o1[0]).attr("rowspan", Number($(o1[0]).attr("rowspanbk")) + d.length);

      o.each(function (ind, oo) {
        $(oo).attr("rowspan", Number($(oo).attr("rowspanbk")) + d.length);
      });
    } else {
      o.each(function (ind, oo) {
        $(oo).attr("rowspan", Number($(oo).attr("rowspanbk")) + d.length);
      });
      var o1 = $("tr#" + id + ".orderrow.first").find("td");
      $(o1[0]).attr("rowspan", Number($(o1[0]).attr("rowspanbk")) + d.length);
    }
  }
};

function hideDetails() {
  orderListUtil.detailsMode = false;
  localStorage.drinkbar_details = false;
  if (typeof orderListUtil.selected != "undefined") {
    var $selected = $(orderListUtil.selected);

    const table = $(".freeze-orderTable > table");

    var timing = $selected.attr("timing");
    var m = table.find("tr#" + timing + ".mainrow").find("td")[0];
    var o = table.find("tr#" + timing + "-0.orderrow.main").find("td")[0];
    var id = $(orderListUtil.selected).attr("id");
    var d = table.find("tr#" + id + ".detailrow");
    $(m).attr("rowspan", $(m).attr("rowspanbk"));
    $(o).attr("rowspan", $(o).attr("rowspanbk"));
    var o = table.find("tr#" + id + ".orderrow.first").find("td");
    var s = id.split("-");
    var o = table.find("tr#" + id + ".orderrow.main, tr#" + id + ".childOrder").find("td[rowspanbk]");
    if (s[s.length - 1] != "0") {
      o.each(function (ind, oo) {
        $(oo).attr("rowspan", Number($(oo).attr("rowspanbk")));
      });
      var id0 = id.substring(0, id.lastIndexOf("-")) + "-0";
      var o = table.find("tr#" + id0 + ".orderrow").find("td");
      $(o[0]).attr("rowspan", $(o[0]).attr("rowspanbk"));
    } else {
      o.each(function (ind, oo) {
        $(oo).attr("rowspan", Number($(oo).attr("rowspanbk")));
      });
      var o = table.find("tr#" + id + ".orderrow").find("td");
      $(o[0]).attr("rowspan", $(o[0]).attr("rowspanbk"));
      // $(o[4]).attr('rowspan', $(o[4]).attr('rowspanbk'));
    }

    table.find("tr#" + $selected.attr("id") + ".detailrow").addClass("hidden");
    table.find("tr#" + $selected.attr("id") + ".detailrow").removeClass("nothidden selected");
    var selected2 = $("tr#" + $selected.attr("id") + ".orderrow");
    selected2.removeClass("expanded");
    selected2.addClass("collapsed");
    //setTimeout(updateFloatTableHead,100);
  }
}

function showAll(force) {
  if (typeof orderListUtil.selected == "undefined") return;
  var attr = $(orderListUtil.selected).attr("id");
  var detailRows = $("tr.orderrow.hidden3[timing=" + attr + "]");
  var shownRows = $("tr.orderrow.hidden3[timing=" + attr + "]");

  detailRows.removeClass("hidden3");
  detailRows.addClass("nothidden3");

  orderListUtil.showAllMode = true;
}

function hideShowAll() {
  orderListUtil.showAllMode = false;
  $("tr.orderrow.nothidden3").addClass("hidden3");
  $("tr.orderrow.nothidden3").removeClass("nothidden3");
}

export const nextState = () => {
  var orders = $(orderListUtil.selected).find("div.order.orderTableItem");
  var l = $(orders).length;
  if (l == 1) {
    return changeStateByTableAndMenuItem(orders, orders.attr("state"), orderListUtil.statesShown[orderListUtil.statesShown.indexOf(orders.attr("state")) + 1], $(orders).attr("timing"));
  }
  var def = $.Deferred();
  return def.promise();
};

export const prevState = () => {
  var orders = $(orderListUtil.selected).find("div.order.orderTableItem");
  var l = $(orders).length;
  if (l == 1) {
    return changeStateByTableAndMenuItem(orders, orders.attr("state"), orderListUtil.statesShown[orderListUtil.statesShown.indexOf(orders.attr("state")) - 1], $(orders).attr("timing"));
  }
  var def = $.Deferred();
  return def.promise();
};

function changeStateByTableAndMenuItem(t, previousState, state, timing) {
  var def = $.Deferred();
  if (typeof state == "undefined") return def;
  var ret = false;
  t.attr("orders")
    .split(",")
    .forEach(function (orderId) {
      var order = orderListUtil.getOrderById(orderId);
      if (timing == null) timing = order.timing;
      var payingMate = getTableMate(getTableMate(order.forWhomId).payingMateId);
      if (previousState == "cancelled" && ((order.paid && !order.paymentRevertable) || mateInState(payingMate, "wantsToPay"))) ret = true;
      if (((order.paid && !order.paymentRevertable) || (order.wasSelectedForPayment && !isAdmin()) || mateInState(payingMate, "wantsToPay")) && states.indexOf(previousState) > states.indexOf(state)) ret = true;
    });
  if (ret) return def;
  if (state == "cancelled") {
    var ret = false;
    t.attr("orders")
      .split(",")
      .forEach(function (orderId) {
        if (ret) return;
        var order = orderListUtil.getOrderById(orderId);
        var payingMate = getTableMate(getTableMate(order.forWhomId).payingMateId);
        if (order != undefined && ((order.paid && !order.paymentRevertable) || mateInState(payingMate, "wantsToPay"))) {
          ret = true;
          return;
        }
        $($("div#rejectionReason").find("input#reasonText")).val("");
        $("div#rejectionReason").modal({
          focus: true,
          keyboard: false
        });
        orderListUtil.focusElement = t;
        $("div#rejectionReason").modal("show");
        select($($("div#rejectionReason .selectable[level=2]").get(0)));
        orderListUtil.revert = true;
      });
    if (ret) return;

    def.resolve();
  } else {
    orderListUtil.revert = false;
    admin.processchangeStateByTableAndMenuItem(
      t,
      previousState,
      state,
      timing,
      null,
      getMenuTypeArray(),
      function (data) {
        if (data.tableOccupation && !data.tableOccupation.active && data.tableOccupation.id == localStorage.tableOccupationSelected) {
          localStorage.removeItem("tableOccupationSelected");
        }
        orderListUtil.occupationsSelected = orderListUtil.occupationsSelected && orderListUtil.occupationsSelected.filter(o => o.id != data.tableOccupation.id);
        try {
          const table = getTable(localStorage.tableNumberSelected);
          if (orderListUtil.occupationsSelected && table && orderListUtil.occupationsSelected.length === 0 && table.pos !== true && orderListUtil.hideSelectNewOrders) {
            orderListUtil.hideSelectNewOrders();
          }
        } catch (ex) {
          console.error("Failed to execute hideSelectNewOrders", ex);
        }
        update(data, function () {
          def.resolve();
        });
        if ((previousState == "producing" || previousState == "accepted") && (state == "delivering" || state == "delivered" || state == "cancelled")) selectNextUnprocessedOrder();
      },
      function (data) {
        updateOrders([]);
        if (orderListUtil.tablesOverview) tableLayout.updateTableOccupations();
      }
    );
  }
  return def.promise();
}

export const simpleChangeStateByTableAndMenuItem = (t, previousState, state, timing, def) => {
  var modal = $(t).parents("#simpleOrderMain");
  modal.hide();
  if (typeof state == "undefined") return;
  var ret = false;
  t.attr("orders")
    .split(",")
    .forEach(function (orderId) {
      var order = orderListUtil.getOrderById(orderId);
      if (order) {
        if (timing === null) timing = order.timing;
        var payingMate = getTableMate(getTableMate(order.forWhomId).payingMateId);
        if (previousState == "cancelled" && (order.paid || mateInState(payingMate, "wantsToPay"))) ret = true;
      }
    });
  if (ret) return;
  if (state == "cancelled") {
    var ret = false;
    t.attr("orders")
      .split(",")
      .forEach(function (orderId) {
        if (ret) return;
        var order = orderListUtil.getOrderById(orderId);
        var payingMate = getTableMate(getTableMate(order.forWhomId).payingMateId);
        if (order != undefined && (order.paid || mateInState(payingMate, "wantsToPay"))) {
          ret = true;
          return;
        }
        $($("div#rejectionReason").find("input#reasonText")).val("");
        $("div#rejectionReason").modal({
          focus: true,
          keyboard: false
        });
        orderListUtil.focusElement = t;
        $("div#rejectionReason").modal("show");
        select($($("div#rejectionReason .selectable[level=2]").get(0)));
        orderListUtil.revert = true;
      });
    if (ret) return;
  } else {
    orderListUtil.revert = false;
    admin.processchangeStateByTableAndMenuItem(
      t,
      previousState,
      state,
      timing,
      null,
      getMenuTypeArray(),
      function (data) {
        update(data, function () {
          if (orderListUtil.tablesOverview) tableLayout.updateTableOccupations();
          if ((previousState == "producing" || previousState == "accepted") && (state == "delivering" || state == "delivered" || state == "cancelled")) selectNextUnprocessedOrder();
          $("div#simpleOrderMain").remove();

          if (state == "accepted" && auth.myStatus.restaurant_settings["tables-overview"].print_accepted_customer_orders == true) {
            var orderIds = typeof t.attr("orders") == "undefined" ? -1 : t.attr("orders");
            var ordersSelected = [];
            orderIds.split(",").forEach(id => {
              ordersSelected.push(Number(id));
            });
            orderListUtil.processOrders(orderListUtil.ordersCache, false);
            printNewOrders(ordersSelected, undefined, undefined, true);
            if (window.hideEditTable)
              window.hideEditTable();
          }
          def && def.resolve();
        });
      },
      function (data) {
        updateOrders([]);
        if (orderListUtil.tablesOverview) tableLayout.updateTableOccupations();
        $("div#simpleOrderMain").remove();
        def && def.resolve();
      }
    );
  }
};

export const selectNextUnprocessedOrder = force => {
  //if (typeof localStorage.productionLineSelected === 'undefined' && !force)
  //	return;
  //console.log(orderListUtil.selected);
  if (orderListUtil.selected) {
    var v1 = $(orderListUtil.selected.nextAll("tr.nothidden.unprocessed").get(0));
    if (v1) {
      $(v1.parents("div.scrollable-y").get(0)).scrollTo(v1.get(0));
      _show(v1);
    }
  } else {
    var v1 = $($("tr.nothidden.unprocessed").get(0));
    if (v1) {
      $(v1.parents("div.scrollable-y").get(0)).scrollTo(v1.get(0));
      _show(v1);
      $(orderListUtil.selected).addClass("pulse");
    }
  }
};

function getRestaurantComment(comment) {
  if (comment.startsWith("$")) return I18n.t("local.rejection_reason[" + comment.substring(1) + "]");
  else return comment;
}

export const getCategoryProperty = (id, property) => {
  var image = _getCategoryProperty(id, admin.categories.activeMenuCategories ? admin.categories.activeMenuCategories : admin.categories.children, property);
  if (image == "" || image == null || typeof image == "undefined") {
    if (property == "image") return null;
    return {};
  }
  return image;
};

function _getCategoryProperty(id, categories, property) {
  var val = null;
  categories.forEach(function (category) {
    if ((category.entityType != "DrinkCategory" && category.entityType != "MealCategory") || val != null) return null;
    if (category.id == id) {
      if (category[property] != "") val = category[property];
      else val = null;
    } else {
      if (category.activeMenuCategories && category.activeMenuCategories.length > 0) val = _getCategoryProperty(id, category.activeMenuCategories, property);
      else if (category.children && category.children.length > 0) val = _getCategoryProperty(id, category.children, property);
    }
  });
  return val;
}

function getMenuTypeArray() {
  return typeof orderListUtil.menuItemType != "undefined" && orderListUtil.menuItemType != null ? [orderListUtil.menuItemType + "Order"] : [];
}

// New selector
$.expr[":"].Contains = function (a, i, m) {
  return (
    $(a)
      .text()
      .toUpperCase()
      .indexOf(m[3].toUpperCase()) >= 0
  );
};

// Overwrites old selecor
$.expr[":"].contains = function (a, i, m) {
  return (
    $(a)
      .text()
      .toUpperCase()
      .indexOf(m[3].toUpperCase()) >= 0
  );
};

/*
function isProportionalMenuItemAddition(menuItem, id, ids) {
  if (!ids)
    ids = [];
  var res = null;
  menuItem = getMenuItem(menuItem.id);
  res = menuItem.menuItemAdditions.find(a => a.addition.id == id);
  if (res)
    return res.proportional;
  menuItem.menuCategoryAdditions.forEach(c => {
    if (ids.indexOf(c.addition.id) !== -1)
      return;
    if (res)
      return;
    ids.push(c.addition.id)
    res = isProportionalCategoryAddition(c.addition.id, id, ids) ? c.proportional : null;
  });
  if (res)
    return res;
  if (ids.indexOf(menuItem.menuCategoryId) === -1)
    res = isProportionalCategoryAddition(menuItem.menuCategoryId, id, ids, true);
  return res;
}

function isProportionalCategoryAddition(menuCategory, id, ids, up) {
  if (!ids)
    ids = [];
  var res = null;
  menuCategory = getMenuCategory(menuCategory);
  if (up) {
    res = menuCategory.menuItemAdditions.find(a => a.addition.id == id);
    if (res)
      return res;
    menuCategory.menuCategoryAdditions.forEach(c => {
      if (ids.indexOf(c.addition.id) !== -1)
        return;
      if (res)
        return;
      if (ids.indexOf(c.addition.id) === -1) {
        ids.push(c.addition.id)
        if (isProportionalCategoryAddition(c.addition.id, id, ids, false))
          res = c.proportional;
      }
    });
    if (res)
      return res;
    if (menuCategory.menuCategoryId && ids.indexOf(menuCategory.menuCategoryId) === -1)
      if (isProportionalCategoryAddition(menuCategory.menuCategoryId, id, ids, up)) {
        res = true;
      }
    if (res)
      return res;
  } else {
    menuCategory.activeMenuItems.forEach(a => {
      if (res)
        return;
      if (a.id == id)
        res = true;
    })
  }
  return res;
}
*/

export const getQuantity = (o, parentOrderQuantity) => {
  if (parentOrderQuantity) {
    const parentOrder = getOrderById(o.parentOrderId);
    if (!parentOrder) {
      console.error("Parent order not found: " + o.parentOrderId);
      parentOrderQuantity = 1;
    } else {
      const parentMenuItem = getMenuItem(parentOrder.menuItem.id);
      try {
        const proportional = o.proportional; //isProportionalMenuItemAddition(parentMenuItem, o.menuItem.id);
        if (proportional) {
        } else {
          parentOrderQuantity = 1;
        }
      } catch (ex) {
        console.log(ex);
        parentOrderQuantity = 1;
      }
    }
  }
  if (typeof parentOrderQuantity == "undefined") {
    parentOrderQuantity = 1;
  }
  var menuItem = getMenuItem(o.menuItem.id);
  var quantityType = o.quantityType ? o.quantityType : menuItem.quantityType;
  if (quantityType == "undefined" || quantityType == "" || quantityType == "none") return "";
  // if (o.quantity == 1 && quantityType == 'pcs')
  // return '';
  var quantityLabel = "";
  var quantity = Math.round(o.quantity * (parentOrderQuantity > 10 ? 1 : parentOrderQuantity) * 1000) / 1000;
  menuItem.availableQuantities.forEach(function (q) {
    if (Math.abs(q.quantity) == Math.abs(quantity)) {
      //quantityLabel = q.quantityType.name;
      quantityLabel = q.quantityType ? getLocale(q.quantityType.name) : q.name;
      if (quantityLabel == "N/A") quantityLabel = "";
    }
  });
  if (quantityLabel != "" && menuItem.entityType == "Meal") {
    return (quantity < 0 ? quantity + " " : "") + quantityLabel;
  } else {
    var v = quantityLabel != "" ? quantityLabel + " " : "";
    v += Math.abs(quantity) == 1 && quantityType == "pcs" ? "" : "(" + (quantity + (quantity == 1 && quantityType == "pcs" ? "" : quantityType == "" ? "" : "&nbsp;" + I18n.t("local." + quantityType))) + ")";
    return v;
  }
}

export const getQuantity2 = (quantity, quantityType, entityType) => {
  if (entityType == "Meal") return "";
  if (Math.abs(quantity) == 1 && quantityType == "pcs") return "";
  if (typeof quantityType == "undefined" || quantityType == "") return "";
  return quantity + " " + I18n.t("local." + quantityType);
};

/*
function getQuantityLabel2(quantityLabel, quantity, quantityType) {
  if (Math.abs(quantity) == 1 && (quantityType == 'pcs' || quantityType=='none'))
    return '';
  return quantity + ' ' + (quantityType == 'pcs' ? '' : (typeof quantityType=="undefined" || quantityType=='none') ? '' : (local[quantityType] + ' ')) + ((quantityLabel && quantityLabel) != '' ? "(" + quantityLabel + ")" : "");
}
*/

export const getQuantityLabel3 = (quantityLabel, quantity, quantityType, entityType) => {
  if (entityType == "Meal" && quantityLabel) return quantityLabel;
  else return quantity + " " + (quantityType == "pcs" ? "" : I18n.t("local." + quantityType) + " ") + (quantityLabel ? "(" + quantityLabel + ")" : "");
}

export const mateInState = (mate, s) => {
  var r = false;
  if (!mate)
    return false;
  mate.states.forEach(function (state) {
    if (state.duration == 0 && state.state == s) r = true;
  });
  return r;
};

function orderInState(order, s) {
  var r = false;
  order.ordersInState.forEach(function (state) {
    if (state.state == s) r = true;
  });
  return r;
}

export const getDuration = t => {
  if (t == undefined) return 0;
  return getDateTime(new Date(t * 1000));
};

function getDurationMilliseconds(t) {
  if (t == undefined) return 0;
  var r = new Date() - new Date(t);
  return r;
}

function getDateTime(now) {
  var year = now.getFullYear();
  var month = now.getMonth() + 1;
  var day = now.getDate() - 1;
  var hour = now.getHours() - (day > 0 ? 0 : 1);
  var minute = now.getMinutes();
  var second = now.getSeconds();
  if (day > 0) return I18n.t("admin_local.more_than_a_day");
  if (month.toString().length == 1) {
    month = "0" + month;
  }
  if (day.toString().length == 1) {
    day = "0" + day;
  }
  if (hour.toString().length == 1) {
    hour = "0" + hour;
  }
  if (minute.toString().length == 1) {
    minute = "0" + minute;
  }
  if (second.toString().length == 1) {
    second = "0" + second;
  }
  var dateTime = (day > 0 ? day + " " + hour + "'" : day > 0 || hour > 0 ? hour + "'" : "") + minute + '"';
  return dateTime;
}

export const isSelectedFiltered = () => {
  var right_pane = $("#right_pane");
  var right_pane_is_visible = right_pane.length > 0 && !right_pane.hasClass("hidden");
  return (typeof orderListUtil.filterOrders != "undefined" && orderListUtil.filterOrders == true) || right_pane_is_visible;
};

export const getMenuItemCategory = id => {
  var menuItem = _getMenuItemCategory(id, admin.categories.activeMenuCategories ? admin.categories.activeMenuCategories : admin.categories.children);
  if (menuItem == "") {
    console.log("failed to find menu item " + id);
    return null;
  }
  return menuItem;
};

function _getMenuItemCategory(id, categories) {
  var val = null;
  categories.forEach(function (item) {
    if (val != null) return;
    if (item.entityType.indexOf("Category") != -1 && item.id == id) {
      val = item;
    } else {
      if (item.activeMenuCategories && item.activeMenuCategories.length > 0) val = _getMenuItemCategory(id, item.activeMenuCategories);
      if (val == null && item.activeMenuItems && item.activeMenuItems.length > 0) val = _getMenuItemCategory(id, item.activeMenuItems);
      if (val == null && item.children && item.children.length > 0) val = _getMenuItemCategory(id, item.children);
    }
  });
  return val;
}

function editOrder() { }

function productionLineInvolved(productionLines, productionLine) {
  var involved = false;
  productionLines && productionLines.split(",").forEach(function (pl) {
    productionLine.split(",").forEach(productionLine => {
      if (productionLine != "" && pl == productionLine) involved = true;
    });
  });
  return involved;
}

function productionLineInvolved2(productionLines, productionLine) {
  var involved = false;
  productionLine.split(",").forEach(pl => {
    involved = involved || productionLines["pl" + pl] !== undefined;
  });
  return involved;
}

export const updateOrderList = (force, created) => {
  const table = $(".freeze-orderTable > table.orderTable");
  var block = table.find("tbody#orderList");
  if (block.length !== 1) return;

  var blockouter = $("div#orders.tab-pane.container");
  var o = orderListUtil.isFiltered() && typeof orderListUtil.orders.count != "undefined" ? orderListUtil.orders : orderListUtil.allOrders;
  orderListUtil.currentOrderSequenceNumber = 0;

  const show = $(".freezeDrinkbarTable,.freezeKitchenTable").length > 0;

  if (((show || ($('#editTable:not(.hidden)').length > 0 && $('#editTable #orders.tab-pane.active').length > 0)) && (block.hasClass("outdated") || force)) || created === true) {
    var tbody = $('<tbody id="orderList"/>');
    var thereAreOrders = false;
    var price = 0;
    const tableOccupation = getTableOccupation(localStorage.tableOccupationSelected);
    var tableMateId = tableOccupation?.tableMates[0]?.id;
    o.tables.forEach(o => {
      //console.log(o.tableOccupation);
      if (o.tableOccupation == "16287") {
        console.log(o.tableOccupation);
      }
    })
    o.tables.forEach(function (order) {
      if (orderListUtil.show(order)) {
        price += order.discountedPrice;
        tableMateId = order.orders[0].orders[0].orders[0].forWhomId;
        thereAreOrders = true;
        orderListUtil.currentOrderSequenceNumber = Math.max(orderListUtil.currentOrderSequenceNumber, order.orderSequenceNumber);
        $("#" + orderListUtil.ordersTemplate)
          .tmpl({ ...order, showTableNumber: orderListUtil.showTableNumber, ...tmplparams(), orderAnother: orderAnother, alltakeaway: tableOccupation ? tableOccupation.takeaway : false })
          .appendTo($(tbody));
      }
    });
    if (orderListUtil.kioskMode) {
      var tableMate = getTableMate(tableMateId);
      var originalPrice = price;
      if (tableMate && tableMate.discount > 0) {

        $("span.nav-link[href='#orders']").html(
          "<div style='display:flex;flex-flow:row;align-items: center;white-space:nowrap'>" +
          (tableOccupation ? (
            "<div style='font-size:3rem'>#" + tableOccupation.seqnr + "</div>" +
            "<div style='font-size:3rem;margin-right:3rem;margin-left:3rem'>/</div>") : "") +
          "<div style='font-size:3rem'>" +
          price.toLocaleString() +
          " " +
          auth.myStatus.restaurant_base_currency.name +
          "</div></div>"
        );
      } else {
        $("span.nav-link[href='#orders']").html(
          "<div style='display:flex;flex-flow:row;align-items: center;'>" +
          (tableOccupation ? ("<div style='font-size:3rem;'>#" + tableOccupation.seqnr + "</div>" +
            "<div style='font-size:3rem;margin-right:3rem;margin-left:3rem'>/</div>") : "") +
          "<div style='font-size:3rem'>"
          + price.toLocaleString() + " " + auth.myStatus.restaurant_base_currency.name +
          "</div>" +
          "</div>"
        );
      }
    } else {
      $("span.nav-link[href='#orders']").html(I18n.t("admin_local.orders"));
    }
    if (thereAreOrders) $("#editTable .tableNumber").hide();
    else $("#editTable .tableNumber").show();

    replaceContent(block, tbody);
    block.removeClass("outdated");
    updateToolTips();
    //$('#editTable table.orderTable').floatThead('reflow');
  } else {
    block.addClass("outdated");
  }
};

export const updateAndCloseModal = (data, handler) => {
  $("#editDiscounts,#markTableMateForPayment").modal("hide");
  $("#editDiscounts,#markTableMateForPayment").hide();
  //if (typeof tableLayout !='undefined') {
  updateTableOccupations([data.tableOccupation], data.orders).done(function () {
    // updateOrders(data.orders);
    // tableLayout.updateTableOccupations();
    if (handler)
      handler();
  });
  //} else {
  //	updateOrders(data.orders);
  //}
};

export const update = (data, handler) => {
  if (!orderListUtil.tableOccupations)
    return;
  //if (orderListUtil.tablesOverview) {
  if (data.tableOccupations || data.tableOccupation) {
    updateTableOccupations(data.tableOccupations || [data.tableOccupation], data.orders).done(function () {
      // updateOrders(data.orders);
      // tableLayout.updateTableOccupations();
      if (typeof handler != "undefined") {
        handler();
      }
    });
  } else {
    orderListUtil.processAllOrdersDone = false;
    processAllOrders(orderListUtil.ordersCache);
    updateTableOccupations([data.tableOccupation], data.orders).done(function () {
      processAllOrders(orderListUtil.ordersCache);
      //updateOrders(data.orders);
      // tableLayout.updateTableOccupations();
      if (typeof handler != "undefined") {
        handler();
      }
    });
  }
}

export const toggleSound = () => {
  localStorage.soundon = !(localStorage.soundon === "true");
  if (localStorage.soundon === "true") {
    $("#sound").removeClass("icon-volume-off");
    $("#sound").addClass("icon-volume-up");
    playNewAcceptedOrderSound();
  } else {
    $("#sound").removeClass("icon-volume-up");
    $("#sound").addClass("icon-volume-off");
  }
}

export const initSound = () => {
  if (localStorage.soundon === "true")
    document.getElementById("silent").play().catch((ex) => {
      messageDialog(I18n.t("local.information"), I18n.t("admin_local.activate_sound")).done(() => {
        document.getElementById("silent").play().catch(() => { });
      })
    });
}

export const playNewAcceptedOrderSound = (unmute = false) => {
  if (unmute && !(localStorage.soundon === "true")) {
    toggleSound();
    return;
  }
  if (!(localStorage.soundon === "true")) return;
  try {
    document.getElementById("front-desk-bells").play().catch(() => {
      messageDialog(I18n.t("admin_local.information"), I18n.t("admin_local.activate_sound")).done(() => {
        document.getElementById("front-desk-bells").play().catch(() => { });
      })
    });
  } catch (ex) {
  }
};


var myMedia;

export const initClickSound = () => {
  try {
    //eslint-disable-next-line no-undef
    if (cordova.file) {
      //eslint-disable-next-line no-undef
      var pathToFile = (cordova.file.applicationDirectory.startsWith("http") ? auth.appServer : cordova.file.applicationDirectory + "www/") + "audio/button5.wav";
      window.resolveLocalFileSystemURL(pathToFile, function (fileEntry) {
        // Convert the file entry to a URL using the cdvfile protocol
        var fileUrl = fileEntry.toURL();

        //eslint-disable-next-line no-undef
        myMedia = new Media(fileUrl,
          function () {

          },
          function (e) {
            //eslint-disable-next-line no-undef
            console.error('Media Error: ' + fileUrl + "     " + JSON.stringify(e));
          }
        );
      });
    }
  } catch (ex) {

  }
}


export const playClickSound = (unmute = false) => {

  if (unmute && !(localStorage.soundon === "true")) {
    toggleSound();
    return;
  }
  if (!(localStorage.soundon === "true")) return;

  //eslint-disable-next-line no-undef
  if (typeof Media !== "undefined" && myMedia) {
    myMedia.setVolume('1.0');
    myMedia.play();
  }
}


export const allBeingPrinted = printStatus => {
  var allBeingPrinted = true;
  var allBeingPrinted2 = false;
  Object.values(printStatus).forEach(v => {
    if (v != 4) allBeingPrinted = false;
    else allBeingPrinted2 = true;
  });
  return allBeingPrinted && allBeingPrinted2;
};

export const isBeingPrinted = printStatus => {
  return Object.values(printStatus).filter(v => v == 4).length > 0;
};

export const isPartPrinted = printStatus => {
  return Object.values(printStatus).filter(v => v == 3).length > 0;
};

export const isPrintFailed = printStatus => {
  return Object.values(printStatus).filter(v => v == 2).length > 0;
};

export const isNotPrinted = printStatus => {
  return Object.values(printStatus).length == 0;
};

export const setTakeAwayOrder = (e) => {
  const takeaway = !$(e).hasClass('takeaway');
  const orders = $(e).parents('tr').attr('orderid');
  if (orders.split(",")[0] < 0) {
    orders.split(",").forEach(o => {
      if (o) {
        const order = getOrderById(o);
        order.takeaway = !order.takeaway;
        updateTableOccupations([], [order]);
      }
    })

  } else
    get("adminService/" + localStorage.restaurantSelected + "/setTakeAwayOrder/" + localStorage.tableOccupationSelected + "/" + takeaway + "?orderids=" + orders).done(data => {
      update(data);
    });
}

export const setTakeAway = (e) => {
  var takeaway = $(e).hasClass("active");

  //if (!takeaway && (isTakeawayTable(localStorage.tableNumberSelected) || !getTable(localStorage.tableNumberSelected).isPos)) {
  if (!takeaway && isTakeawayTable(localStorage.tableNumberSelected)) {
    const element = document.getElementById('takeawaydialog');

    function unmount() {
      ReactDOM.unmountComponentAtNode(element);
    }
    function setCustomerDetails(details) {
      console.log(details)
      const d = {
        email: details.email,
        phone: details.phone,
        name: details.name,
        surname: details.surname,
        user_comment: details.user_comment,
        address: details.address,
        door: details.door,
        postcode: details.postcode,
        town: details.town,
        start: details.start.toDate().getTime(),
        end: details.end.toDate().getTime(),
        payment_method: details.payment_method,
        installments_strategy: details.installments_strategy,
        latitude: details.latitude ? "" + details.latitude : undefined,
        longitude: details.longitude ? "" + details.longitude : undefined,
        update: details.update,
        comment: details.comment
      }
      if (details.id && !isNaN(details.id)) {
        d.id = details.id;
      }
      post("adminService/" + localStorage.restaurantSelected + "/updateBooking/" + localStorage.tableOccupationSelected, d).done(data => {
        update(data)
      });
      unmount();
    }

    function revert() {
      unlockTable(localStorage.tableOccupationSelected, localStorage.tableNumberSelected, true, data => {
        unmount();
        hideEditTable();
        hideEditTable();
        update(data);
      }, unmount);
    }

    if (element) {
      const tableOccupation = getTableOccupation(localStorage.tableOccupationSelected);
      ReactDOM.render(<CustomerDeliveryDetails onsave={setCustomerDetails} myStatus={auth.myStatus} tableOccupation={tableOccupation} start={tableOccupation.bookedOccupationStart} end={tableOccupation.bookedOccupationEnd} values={tableOccupation.bookedProperties} revert={revert} unmount={unmount} />, element);
    }
  }
  get("adminService/" + localStorage.restaurantSelected + "/setTakeAway/" + localStorage.tableOccupationSelected + "/" + !takeaway).done(data => {
    update(data);
  });
};


export const addRejectionReason = () => {
  if (!$($("div#rejectionReason").find("label.reason.active")).attr("id"))
    return;
  var id = $($("div#rejectionReason").find("label.reason.active")).attr("id").substring(6);
  var comment = $($("div#rejectionReason").find("input#reasonText")).val();
  if (comment == "") comment = "$" + id;
  var modal = $("div#rejectionReason").modal();
  var orders = orderListUtil.focusElement;
  orderListUtil.revert = false;
  admin.processchangeStateByTableAndMenuItem(orders, $(orders).attr("state"), "cancelled", $(orders).attr("timing"), comment, getMenuTypeArray(), update);
};

export const discountRoleCheck = (discountType) => {
  if (discountType.roles.length)
    if (!admin.hasRole(auth.myStatus.roles, discountType.roles)) return false;
  if (discountType.excludedRoles.length)
    if (admin.hasRole(auth.myStatus.roles, discountType.excludedRoles)) return false;
  return true;
}

const _orderListUtil = {
  newOrders: [],
  orders: {},
  allOrders: {},
  detailsMode: false,
  showAllMode: false,
  statesShown: ["cancelled", "ordered", "accepted", "producing", "delivering", "delivered"],
  menuItemType: null,
  tableMates: [],
  ordersPaidByMap: {},
  dragged: false,
  drag: false,
  orderEditorVisible: false,
  mousedownHandler: mousedownHandler,
  keyDownHandler: undefined,
  kioskMode: false,
  hidePaid: false,
  showTableNumber: false,
  isFiltered: _isFiltered,
  filter: () => { return true },
  getCategoryProperty: getCategoryProperty,
  getRestaurantComment: getRestaurantComment,
  addRejectionReason: addRejectionReason,
  selectNextUnprocessedOrder: selectNextUnprocessedOrder,
  simpleChangeStateByTableAndMenuItem: simpleChangeStateByTableAndMenuItem,
  changeStateByTableAndMenuItem: changeStateByTableAndMenuItem,
  prevState: prevState,
  nextState: nextState,
  hideShowAll: hideShowAll,
  showAll: showAll,
  hideDetails: hideDetails,
  showDetails: showDetails,
  toggleShowAll: toggleShowAll,
  toggleDetails: toggleDetails,
  _show2: _show2,
  _show: _show,
  selectNextOrder: selectNextOrder,
  selectPreviousOrder: selectPreviousOrder,
  selectRow: selectRow,
  setSelected: setSelected,
  updateHooks: updateHooks,
  printNewOrders: printNewOrders,
  processOrders: processOrders,
  updateOrders: updateOrders,
  processAllOrdersDone: false,
  processAllOrders: processAllOrders,
  currentOrderSequenceNumber: 0,
  getWaitingTime: getWaitingTime,
  processOrdersDone: () => { },
  getOrderById: getOrderById,
  ordersTemplate: "ordersTemplate",
  tablesOverview: false,
  refreshPrinters: refreshPrinters,
  resultCache: {
    orderlist: {},
    statistics: {},
    tables: [],
    ordersByCustomer: [],
    ordersByPayer: [],
    ordersByTiming: [],
    ordersByMenuItem: [],
    productionLineUsage: {},
    tableStatus: {},
    tableOccupations: {},
    states: null,
  },
  showCloseButton: false,
  discountRoleCheck: discountRoleCheck
};

const orderListUtil = {
  ..._orderListUtil
};

export const resetOrderListUtil = () => {
  const props = orderListUtil.props;
  Object.keys(orderListUtil).forEach(key => delete orderListUtil[key]);
  Object.keys(_orderListUtil).forEach(key => (orderListUtil[key] = _orderListUtil[key]));
  orderListUtil.props = props;
};

export const getAddresses = (id, date) => {
  const pickupTableNumber = getPickupTableNumber();
  const homeDeliveryTableNumber = getHomeDeliveryTableNumber();

  var text = "";
  const occupation = getTableOccupation(id);
  const restaurantTableOccupation = occupation.restaurantTables[0];
  var number = restaurantTableOccupation.restaurantTable.number;

  const aIsScheduled = number === pickupTableNumber || number === homeDeliveryTableNumber;
  var start = occupation.occupationStart != null && !aIsScheduled ? occupation.occupationStart : occupation.bookedOccupationStart;
  var end = occupation.occupationEnd != null && !aIsScheduled ? occupation.occupationEnd : occupation.bookedOccupationEnd ? occupation.bookedOccupationEnd : occupation.bookedOccupationStart;

  const style = "!ESC|4C!ESC|cA";

  if (start) {

    text += "<span>";

    if (moment(start).format("YYYY-MMM-DD") === moment().format("YYYY-MMM-DD"))
      text += "<div class='date' style='font-size:100%'>" + style + I18n.t("admin_local.today") + " " + moment(start).format("HH:mm") + "-" + moment(end).format("HH:mm") + "!LF</div>";
    else if (moment(start).format("YYYY-MMM-DD") === moment().add(1, 'day').format("YYYY-MMM-DD"))
      text += "<div class='date' style='font-size:100%'>" + style + I18n.t("admin_local.tomorrow") + " " + moment(start).format("HH:mm") + "-" + moment(end).format("HH:mm") + "!LF</div>";
    else if (moment(start).format("YYYY-MMM-DD") === moment().add(-1, 'day').format("YYYY-MMM-DD"))
      text += "<div class='date' style='font-size:100%'>" + style + I18n.t("admin_local.yesterday") + " " + moment(start).format("HH:mm") + "-" + moment(end).format("HH:mm") + "!LF</div>";
    else if (moment(start).isAfter(moment().add(1, 'day').startOf("day")))
      text += "<div class='date' style='font-size:100%'>" + style + moment(start).format("YYYY-MMM-DD") + "!LF</div>";
    else {
      text += "<div class='date' style='font-size:100%'>" + style + moment(start).format("HH:mm") + "!LF</div>";
    }

    text += "!LF</span>"
  }

  if (occupation.bookedProperties) {
    text += "<span>" + style;
    text += occupation.bookedProperties.surname ? occupation.bookedProperties.surname + " " : "";
    text += (occupation.bookedProperties.name ? occupation.bookedProperties.name : occupation.bookedProperties.email ? occupation.bookedProperties.name : "");
    text += "!LF</span><span>" + style + (occupation.bookedProperties.postcode ? occupation.bookedProperties.postcode + " " : "") + (occupation.bookedProperties.address ? occupation.bookedProperties.address : "");
    text += "!LF</span><span>" + style + (occupation.bookedProperties.phone ? occupation.bookedProperties.phone : "");
  }
  text += "!LF!LF</span>";

  //eslint-disable-next-line no-unused-vars
  const amount = getAmountToPay(restaurantTableOccupation.tableMateIds[0], date);
  var roundFunction = auth.myStatus.restaurant_base_currency["javaScriptRoundFunction"];
  //eslint-disable-next-line no-eval
  const roundedAmount = eval(roundFunction);

  if (roundedAmount !== 0) {
    text += "!LF<div class='amount'>" + style + roundedAmount + " " + auth.myStatus.restaurant_base_currency.name + "!LF</div>";
    if (occupation.bookedProperties && occupation.bookedProperties.payment_method && getPaymentMethod(occupation.bookedProperties.payment_method)) {
      text += "<div>" + style + getPaymentMethod(occupation.bookedProperties.payment_method).method.name + "!LF</div>";
    }
  } else {
    text += "!LF<div class='amount'>" + style + I18n.t("admin_local.is_paid") + "!LF</div>";
  }

  const path = auth.myStatus.restaurant_longitude + ',' + auth.myStatus.restaurant_latitude + "/" + occupation.bookedProperties.longitude + "," + occupation.bookedProperties.latitude;

  const url = 'https://www.google.com/maps/dir/' + path + '/@' + auth.myStatus.restaurant_longitude + ',' + auth.restaurant_latitude + ',14z';

  text += "<div>!ESCqrcode: " + url + "!LF!LF!LF</div>";

  return text;
}


export default orderListUtil;
